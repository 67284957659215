import React, { useEffect, useRef, useState } from "react"
import { Card, Grid } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { color, courtTypeObj } from "./constant"
import moment from "moment"
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput"
import { bubbleSort } from "./sort"
import { Confirm } from "./showAlert"

import addOnSVG from "../assets/addon.svg";
import { getMyAddOn } from "service/addOnService"
import { loadImages } from "./loadImages"

const hoursArray = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00"
]

// Booked
// Not Available

function BookingScheduleList ({
    data,
    date,
    court,
    onChangeSchedule, // format { date: "", hours: []}
    disableSelectionClass,
    disableSelectionProduct,
    selectedSession,
    allowMultiple = false,
    allowMultipleCourt = false,
    clearSelectionFormOnStateChange = false,
    checkTime = true,
    detailData
}) {

    const myRef = useRef(null);
    const [ myAddOn, setMyAddOn ] = useState([])
    const [ rerender, setRerender ] = useState(Date.now)
    const [ selectedForm, setSelectedForm ] = useState([])
    const hours = data ? data.hours : []

    useEffect(() => {
        if (selectedSession) {
            let exist = false
            setMyAddOn([])
            for (let i = 0; i < selectedSession.length; i ++) {
                if (date == selectedSession[i].date && court == selectedSession[i].court) {
                    exist = true
                    setSelectedForm(selectedSession[i].hours)
                    return;
                }
            }
            if (clearSelectionFormOnStateChange && !exist) {
                setSelectedForm([])
            }
        }
    }, [selectedSession])

    useEffect(() => {
        if (allowMultiple && !allowMultipleCourt) { if (data._id != court && data.date == date) { setSelectedForm([]) } }
        else {
            if (!allowMultiple) { if (data.date != date) { setSelectedForm([]) } }
            if (!allowMultipleCourt) { if (data._id != court) { setSelectedForm([]) } }
        }
    }, [date, court])


    useEffect(() => {
        if (myAddOn && myAddOn.length > 0) { 
            setTimeout(function() {
                myRef.current.scrollIntoView({
                    behavior: 'smooth'
                })   
            }, 200);
        }
    }, [myAddOn]);

    const onChange = (e) => {
        setSelectedForm(e)
        if (!allowMultiple) { onChangeSchedule({
            date: data.date, hours: e
        }) } else if (!allowMultipleCourt) { onChangeSchedule({
            court , date: data.date, hours: e
        }) } else { onChangeSchedule({
            date: data.date, hours: e
        })}
        setRerender(Date.now)
    }

    const functionCheckHours = () => {
        if (disableSelectionClass) {
            let thereisClass = null
            let { currentClass, court, date } = disableSelectionClass

            for (let i = 0; i < currentClass.length; i ++) {
                if (currentClass[i].date == date) {
                    if (court == currentClass[i].court) { 
                        thereisClass = currentClass[i].hours; 
                        break; 
                    }
                }
            }
            
            return thereisClass
        }
        
        if (disableSelectionProduct) {
            let thereisOrder = []
            for (let i = 0; i < disableSelectionProduct.length; i ++) {
                let { hourObjs, hours } = disableSelectionProduct[i]
                console.log('here check checkhours item', date, disableSelectionProduct[i])
                for (let j = 0; j < hourObjs.length; j ++) {
                    if ((disableSelectionProduct[i].date == date) 
                    && (hourObjs[j].notAvailable)) {
                        thereisOrder.push(hourObjs[j].hours)
                    }
                }
            }
            return thereisOrder
        }

        if (detailData) {
            // check current Data
            let { item, date, court } = detailData

            let isExist = false
            let hours = []

            if (item.schedule) {
                for (let i = 0; i < item.schedule.length; i ++) {
                    if (!item.schedule[i].isActive &&
                        moment(date).format('dddd') == item.schedule[i].day) {
                        return hoursArray
                    }
                    if (moment(date).format('dddd') == item.schedule[i].day) {
                        for (let j = 0; j < hoursArray.length; j++) {
                            if (item.schedule[i].hours.indexOf(hoursArray[j]) < 0) {
                                isExist = true
                                hours = hours.concat(hoursArray[j])
                            }
                        }
                    }
                }
            }

            // if (!item.userBookedSession || !item.serviceSession || !item.offSchedule) {
            if (!item.serviceSession && !item.offSchedule) {
                if (hours.length == 0) { return null }
            }
            
            // for (let i = 0; i < item.userBookedSession.length; i ++) {
            //     if (item.userBookedSession[i].date == date 
            //         // && item.userBookedSession[i].court == court
            //     ) {
            //         hours = hours.concat(item.userBookedSession[i].hours)
            //         isExist = true
            //     }
            // }
            // let isBooked = false
            // let isNotAvailable = false
            for (let i = 0; i < item.serviceSession.length; i ++) {
                if (item.serviceSession[i].date == date 
                    // && item.serviceSession[i].court == court
                ) {
                    // Booked
                    hours = hours.concat(item.serviceSession[i].hours)
                    // isBooked = true
                    isExist = true
                }
            }
            for (let i = 0; i < item.scheduleOff.length; i ++) {
                if (item.scheduleOff[i].date == date) {
                    // Not Available
                    hours = hours.concat(item.scheduleOff[i].hours)
                    // isNotAvailable = true
                    isExist = true
                }
            }

            if (isExist) { return hours }

        }
        return null
    }

    const functionCheckInfo = (value) => {
        // console.log('here value', value)
        if (disableSelectionProduct) {

            let isAwaitingPayment = false
            let isBooked = false
            let isNotAvailable = false
            let hours = []

            if (detailData) {
                let { item, date, court } = detailData
                for (let i = 0; i < disableSelectionProduct.length; i ++) {
                    let { hourObjs } = disableSelectionProduct[i]
                    console.log('here check', date, disableSelectionProduct[i])
                    for (let j = 0; j < hourObjs.length; j ++) {
                        if ((disableSelectionProduct[i].date == date) 
                        && (hourObjs[j].notAvailable)) {
                            hours = hours.concat(hourObjs[j].hours)
                        }
                    }
                }
                for (let i = 0; i < item.awaitingItemSession.length; i ++) {
                    if (item.awaitingItemSession[i].date == date
                        && item.awaitingItemSession[i].hours.indexOf(value) >= 0
                    ) {
                        // Not Available
                        hours = hours.concat(item.awaitingItemSession[i].hours)
                        isBooked = false
                        isAwaitingPayment = true
                        // isExist = true
                    }
                }
            }

            return {
                isAwaitingPayment,
                isBooked,
                isNotAvailable
            }
        }
        if (detailData) {
            // check current Data
            let { item, date, court } = detailData
            let isExist = false
            let hours = []

            if (item.schedule) {
                for (let i = 0; i < item.schedule.length; i ++) {
                    if (!item.schedule[i].isActive &&
                        moment(date).format('dddd') == item.schedule[i].day) {
                        return hoursArray
                    }
                    if (moment(date).format('dddd') == item.schedule[i].day) {
                        for (let j = 0; j < hoursArray.length; j++) {
                            if (item.schedule[i].hours.indexOf(hoursArray[j]) < 0) {
                                isExist = true
                                hours = hours.concat(hoursArray[j])
                            }
                        }
                    }
                }
            }

            // if (!item.userBookedSession || !item.serviceSession || !item.offSchedule) {
            if (!item.serviceSession && !item.offSchedule) {
                if (hours.length == 0) { return null }
            }
            
            // for (let i = 0; i < item.userBookedSession.length; i ++) {
            //     if (item.userBookedSession[i].date == date 
            //         // && item.userBookedSession[i].court == court
            //     ) {
            //         hours = hours.concat(item.userBookedSession[i].hours)
            //         isExist = true
            //     }
            // }
            let isBooked = false
            let isAwaitingPayment = false
            let isNotAvailable = false
            for (let i = 0; i < item.serviceSession.length; i ++) {
                if (item.serviceSession[i].date == date 
                    && item.serviceSession[i].hours.indexOf(value) >= 0
                    // && item.serviceSession[i].court == court
                ) {
                    // Booked
                    hours = hours.concat(item.serviceSession[i].hours)
                    isBooked = true
                    isExist = true
                }
            }
            for (let i = 0; i < item.scheduleOff.length; i ++) {
                if (item.scheduleOff[i].date == date
                    && item.scheduleOff[i].hours.indexOf(value) >= 0
                ) {
                    // Not Available
                    hours = hours.concat(item.scheduleOff[i].hours)
                    isNotAvailable = true
                    isExist = true
                }
            }
            for (let i = 0; i < item.awaitingServiceSession.length; i ++) {
                if (item.awaitingServiceSession[i].date == date
                    && item.awaitingServiceSession[i].hours.indexOf(value) >= 0
                ) {
                    // Not Available
                    hours = hours.concat(item.awaitingServiceSession[i].hours)
                    isBooked = false
                    isAwaitingPayment = true
                    isExist = true
                }
            }

            if (isExist) { return {
                isAwaitingPayment,
                isBooked,
                isNotAvailable
            } }

        }
        return null
    }

    const functionLoadMyAddOn = async (value) => {
        let { data, error } = await getMyAddOn({
            date: date,
            hours: value
        })
        if (error || !data) { setMyAddOn([]) }
        if (data) {
            setMyAddOn(data)
        }
    }

    return (
        <Grid item xs={12}>
            <div ref={myRef}/>
            <TCLabel bold>{data ? `${courtTypeObj[data.courtType]} ${data.name}` : ""}</TCLabel>
            <HoursComponent detailData={detailData} form={selectedForm} onChange={onChange} availableHours={hours} 
                allowCheckTime={checkTime}
                disabledHours={functionCheckHours}
                disabledInfo={functionCheckInfo}
                date={date}
                functionLoadMyAddOn={functionLoadMyAddOn}
                myAddOn={myAddOn}
            />
        </Grid>
    )
}

function HoursComponent ({
    detailData,
    form,
    onChange,
    availableHours,
    allowCheckTime,
    disabledHours,
    disabledInfo,
    functionLoadMyAddOn,
    myAddOn,
    date
}) {

    const [ userBookedSession, setUserBookedSession ] = useState(null)

    useEffect(() => {
    }, [form])

    useEffect(() => {
        if (detailData) {
            let { item } = detailData
            setUserBookedSession(item.userBookedSession)
        }
    }, [detailData])

    const updateSelectedTime = (value) => {
        
        let index = form.indexOf(value);
        let newForm = form;
        
        if (value && index < 0) {
            checkLoadMyAddOn(value)
            newForm = [ ...form, value ];
        } else if (index >= 0) {
            newForm.splice(index, 1);
        }

        newForm = bubbleSort(newForm)
        if (allowCheckTime) {
            let isValid = checkSelectionTime(newForm)
            if (!isValid) { newForm = [] }
        }

        onChange(newForm)
    }

    const checkLoadMyAddOn = async (value) => {
        let loadMyAddOn = checkBookedItem(value)
        if (loadMyAddOn) { 
            functionLoadMyAddOn(value)
        }
    }

    const checkBookedItem = (value) => {
        if (userBookedSession) { 
            for (let i = 0; i < userBookedSession.length; i ++) {
                if (userBookedSession[i].hours.indexOf(value) >= 0 &&
                    userBookedSession[i].date == date
                ) {
                    return true;
                }
            }
            return false
        }
    }

    return (
        <Grid container>
            {/* <Grid mb={1} item>
                <TCLabel bold>Time</TCLabel>
            </Grid> */}
            <Grid item container spacing={0.98}>
            {
                availableHours.map( value => {
                    return (
                        <Grid item>
                            {
                                checkBookedItem(value) && <div style={{
                                    position:'relative',
                                }}>
                                    <div style={{width: '0.9em', height: '1em', display: 'flex',
                                        backgroundColor: color.primary,
                                        right: -4,
                                        top: -2,
                                        position: 'absolute',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 'calc(0.9em / 3)'
                                    }}>
                                            <img src={addOnSVG} style={{width: '0.6em', height: '0.8em'}}/>
                                    </div>
                                </div>
                                
                            }
                            <div className={
                                    (disabledHours() && disabledHours().indexOf(value) >= 0) ? (disabledInfo(value) && disabledInfo(value).isAwaitingPayment) ? 'selection-idle' : 'selection-disabled' :
                                    form.indexOf(value) >= 0 ? 'selection-selected' : checkTime(form, value, allowCheckTime) ? 'selection-available' : 'selection-disabled'
                                } // selection-disabled 
                                style={{textAlign: 'center', padding: 5, minWidth: 25, borderRadius: 8}}
                                onClick={() => {
                                    if ((disabledHours() && disabledHours().indexOf(value) >= 0)) { 
                                        let { isBooked, isAwaitingPayment, isNotAvailable } = disabledInfo(value)
                                        if (detailData) { 
                                            if (detailData.item && (detailData.item.type == "rent" || detailData.item.type == "sell")) {
                                                Confirm("", isBooked ? "Booked" : isAwaitingPayment ? "Awaiting payment. Please check again" : "Booked")
                                                return;
                                            } 
                                        }
                                        Confirm("", isBooked ? "Booked" : isAwaitingPayment ? "Awaiting payment. Please check again" : "Not Available")
                                        return; 
                                    }
                                    if (!checkTime(form, value, allowCheckTime)) { return; }
                                    updateSelectedTime(value)
                                }}
                            >
                                {/* <MKTypography variant="small" fontWeight="regular" color="inherit">{value.label}</MKTypography> */}
                                <div>{value}</div>
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
            {
                (myAddOn && myAddOn.length > 0) &&
                <Grid xs={12}>
                    <div className="separation-line"/>
                    <TCLabel>My Add-On</TCLabel>
                    {
                        myAddOn.map(value => {
                            return (
                                <Grid item xs={12} lg={12} mb={2}
                                    sx={{ paddingTop: '0px !important'}}>
                                    <Card>
                                        <Grid container xs={12} p={1}>
                                            <div style={{display: 'flex'}}>
                                                <div style={{ marginRight: 8}}>
                                                    <img src={loadImages(value.productInfo.image)} width={60} height={80} 
                                                        style={{objectFit: 'contain'}} />
                                                </div>
                                                <div>
                                                    <Grid item container xs>
                                                        <Grid item xs={12}>
                                                            <TCLabel bold>{value.productInfo.category}</TCLabel>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TCLabel>{value.productInfo.name}</TCLabel>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
        </Grid>
    )
}

function checkTime (
    hours, currentHours, allowCheckTime
) {
    let valid = true
    if (!allowCheckTime) { return true }
    if (hours.length > 0) {
        let currHoursNumber = Number(currentHours.split(":")[0])
        let firstHoursNumber = Number(hours[0].split(":")[0])
        let lastHoursNumber = Number(hours[hours.length - 1].split(":")[0])
        let check1 = Math.abs(firstHoursNumber - currHoursNumber)
        let check2 = Math.abs(lastHoursNumber - currHoursNumber)
        if (check1 > 1 && check2 > 1) { valid = false }
    }
    return valid
}

function checkSelectionTime (hours) {
    let valid = true
    console.log('here new check time',hours)
    for (let i = 0; i < hours.length; i ++) {
        if (i > 0) {
            let beforeHoursNumber = Number(hours[i - 1].split(":")[0])
            let hoursNumber = Number(hours[i].split(":")[0])
            console.log('here new check time', Math.abs(hoursNumber - beforeHoursNumber))
            if (Math.abs(hoursNumber - beforeHoursNumber) > 1) {
                valid = false
                break
            }
        }
    }
    return valid
}

export { BookingScheduleList }