import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, Grid, Icon, Popover, Box } from "@mui/material";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import moment from "moment";
import equality from "../../../assets/equality.svg";
import { currencyFormat } from "helper/numberFormat";
import MKButton from "components/MKButton";

import { Hours } from "helper/constant";
import { toLocalGMT, toGMT7, momentFormat } from "helper/time";
import { useSwipeable } from "react-swipeable";
import { addMonths, subMonths } from "date-fns";
import Calendar from "react-calendar";
import TCAccordion from "components/TopCourtComponents/Accordion/TopCourtAccordion";
import TCNumberInput from "components/TopCourtComponents/Input/TopCourtCounterNumber";
import { Confirm } from "helper/showAlert";
import { color } from "helper/constant";
import { useNavigate } from "react-router-dom";
import TCDrawer from "components/TopCourtComponents/Drawer/TopCourtDrawer";
import { deleteCart } from "pages/Booking/Function";
import { checkUserisLoggedIn } from "pages/Booking/Function";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import { getScheduleList } from "service/orderService";
import { getClassCart, addClassToCart } from "service/classService";
import ClassBookingDetail from "./ClassBookingDetail";
import { submitOrderClass } from "service/classService";
import ChecklistIcon from "../../../assets/checklist.png";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { ConfirmationOneButton } from "helper/showAlert";
import { getUserInfo } from "helper/localStorage";
import SlideDialog from "components/SlideDialog/SlideDialog";
import UserCard from "components/TopCourtComponents/User/UserCard";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import QRPage from "pages/Orders/QRPage";
import MonthSelection from "pages/Booking/MonthSelection";
import Coach from "./Coach";

function ClassDetailv2Component({
    item,
    disableView,
    maxValue,
    setSelectionClass,
    setShowLogin,
    isMyClass,
    checkAgeandGender,
    disableJoinClass,
    reloadData,
    fromJoin,
    changeDate
}) {

    const myRef = useRef(null)
    const navigate = useNavigate()
    
    const [ user, setUser ] = useState(null)
    const [ number, setNumber ] = useState(0)
    const [ selectedValue, setSelectedValue ] = useState([])
    const [ classFull, setClassFull ] = useState(false)
    const [ classNotAvailable, setClassNotAvailable ] = useState(false)
    const [ showBooking, setShowBooking ] = useState(false)
    const [ reRender, setRerender ] = useState(Date.now)
    const [ loading, setLoading ] = useState(false)
    const [ booking, setBooking ] = useState([])
    const [ bottomSheetHeight, setBottomSheetHeight ] = useState(null)
    const [ openCalendar, setOpenCalendar] = useState(false)

    const [ hourList, setHourList ] = useState([])
    const [ availableHours, setAvailableHours ] = useState(Hours)
    const [ swipeState, setSwipeState ] = useState(0)

    const defaultDate = (item && new Date(item.classSchedule.startClassDateString) > new Date()) ? new Date(item.classSchedule.startClassDateString) : disableJoinClass ? new Date(item.classSchedule.startClassDateString) : new Date()

    const [ selectedDefaultDate, setSelectedDefaultDate ] = useState(defaultDate)
    const [ form, setForm ] = useState({
        selectedDate: toLocalGMT(defaultDate),
        date: toGMT7(defaultDate),
        month: toGMT7(defaultDate, 'MM'),
        year: toGMT7(defaultDate, 'YYYY'),
        court: null,
        hours: [],
        coach: [],
        hourObject: [],
        totalSessions: 0,
        totalPrice: 0,
    })

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const onChangeHourObject = (hour, value) => {
        setForm(prev => ({
            ...prev,
            hourObject: [
                ...checkSimiliarHours(prev, hour, value),
            ],
            localHourObject: [
                ...prev.hourObject,
                ...checkSimiliarHours(prev, hour, value),
            ]
        }))
    }

    const checkSimiliarHours = (prev, hour, value) => {
        let newHourObject = []
        for (let i = 0; i < prev.hourObject.length; i ++) {
            let hourObject = prev.hourObject[i]
            if (hourObject.hours.indexOf(hour) >= 0) {
                hourObject = {
                    ...hourObject,
                    ...value
                }
                newHourObject.push(hourObject)
            } else {
                newHourObject.push(hourObject)
            }
        }
        return newHourObject
    }

    const bottomSheetRef = useCallback(n => {
        if (n !== null) {
            setBottomSheetHeight(n.getBoundingClientRect().height)
        }
    })

    useEffect(() => {
        let newHours = checkHours(form.date)
        console.log('available hours', 'check item', newHours)
        setAvailableHours(newHours)
        checkClassHours(form.date)
        let bookingInfo = getBookingObject(form.date, booking, item)
        onChange({
            hours: bookingInfo.hours, 
            totalSessions: bookingInfo.totalSessions, 
            totalPrice: bookingInfo.totalPrice,
            coach: bookingInfo.coach || [],
            hourObject: bookingInfo.hourObject || [],
            backendHourObject: getBookingObject(form.date, booking, item).hourObject || [],
        });
    }, [item])

    useEffect(() => {
        getBookingsDateList()
        getClassBooking()
    }, [])

    useEffect(() => {
        console.log('here backendHourObject', getBookingObject(form.date, booking, item).hourObject)
        onChange({
            backendHourObject: getBookingObject(form.date, booking, item).hourObject || [],
        });
    }, [booking])

    useEffect(() => {
        changeDate(form.date)
    }, [form.date])

    useEffect(() => {
        console.log('available hours', availableHours)
    }, [availableHours])

    useEffect(() => {
        if (fromJoin) { executeScroll() }
    }, [fromJoin])

    const executeScroll = () => {
        setTimeout(function() {
            myRef.current.scrollIntoView({
                behavior: 'smooth'
            })   
        }, 200);
    }
    
    const getBookingsDateList = async (e) => {
        try {
            let { data, error } = await getScheduleList()
            if (error) { console.log(error) }
            if (data) { 
                setHourList(data) 
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getClassBooking = async (e) => {
        try {
            let { data, error } = await getClassCart({ classId: item._id })
            if (error) { console.log(error) }
            if (data) { 
                let bookingInfo = getBookingObject(form.date, data, item)
                onChange({
                    hours: bookingInfo.hours, 
                    coach: bookingInfo.coach || [],
                    totalSessions: bookingInfo.totalSessions, 
                    totalPrice: bookingInfo.totalPrice,
                    hourObject: bookingInfo.hourObject || []
                });
                setBooking(data) 
                setNumber(data.length > 0 ? data[0].pax : 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (swipeState == 1) { return; }
            setSwipeState(swipeState + 1)

            let monthPlus1 = swipeState + 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
        onSwipedRight: () => {
            if (swipeState == 0) { return; }
            setSwipeState(swipeState - 1)

            let monthPlus1 = swipeState - 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
    });

    useEffect(() => {
        setSelectionClass(selectedValue)
    }, [selectedValue])

    const calculateSummary = () => {
        let totalSession = 0;
        let totalPrice = 0;
        let totalClassBundle = 0;
        let totalPack = user ? user.sessions : 0;

        booking.map( value => {
            if ((value.hours && value.hours.length > 0) || value.totalSessions > 0) {
                totalClassBundle += (value.totalSessions * value.pax)
                // totalSession += value.totalSessions
                totalSession += (value.totalSessions * value.pax)
                totalPrice += value.totalPrice
            }
        })

        // calculate bundle
        let afterPrice = totalPrice
        let bundleUsed = 0
        if ((totalClassBundle - item.bundle) > 0) {
            afterPrice = (totalPrice / totalClassBundle) * (totalClassBundle - item.bundle)
        } else { afterPrice = 0 }
        bundleUsed = (totalClassBundle - item.bundle) < 0 ? totalClassBundle : item.bundle

        return { totalSession, totalClassBundle, totalPrice, totalPack, bundleUsed, afterPrice}
    }

    const onDeleteBookings = (e) => {
        deleteCart(e._id, e, setBooking, setLoading)
    }

    const checkClassHours = (e) => {
        if (item.classSchedule) {
            let dates = item.classSchedule.dates
            let date = moment(e).format('YYYY-MM-DD')
            if (dates.indexOf(date) >= 0) {
                setAvailableHours(checkHours(date))
            } else {
                setAvailableHours(checkHours(date, true))
            }
        } else {
            let date = moment(e).format('YYYY-MM-DD')
            setAvailableHours(checkHours(date))
        }
    }

    const checkHours = (date, disabled = false) => {
        let newHours = []
        if (disabled) {
            for (let i = 0; i < item.hours.length; i++) {
                let hoursInfo = {
                    label: item.hours[i],
                    active: false,
                    isAvailable: false,
                    offDays: true,
                    maxParticipant: item.playersCapacity,
                }
                newHours.push(hoursInfo)
            }
            return newHours
        }
        if (item.classSchedule.dateObj) {
            for (let i = 0; i < item.classSchedule.dateObj.length; i ++) {
                if (date == item.classSchedule.dateObj[i].date) { 
                    for (let j = 0; j < item.classSchedule.dateObj[i].hours.length; j++) {
                        let hoursInfo = item.classSchedule.dateObj[i].hours[j]
                        let { isAvailable, courtExist } = hoursInfo
                        hoursInfo.active =  true
                        hoursInfo.isAvailable = isAvailable && courtExist
                        newHours.push(hoursInfo)
                    }
                }
            }
        } else {
            for (let i = 0; i < Hours.length; i ++) {
                let hoursDetail = Hours[i]
                if (item.hours.indexOf(hoursDetail.label) >= 0) {
                    hoursDetail.active = true
                    hoursDetail.isAvailable = true
                    newHours.push(hoursDetail)
                } else {
                    hoursDetail.active = false
                    hoursDetail.isAvailable = false
                }
            }
        }
        return newHours
    }

    const onAddBookings = async (e) => {
        if (!form.date || form.hours.length == 0) {
            Confirm("", "Please select session time")
            return;
        }
        if (item.classSchedule.coaches) {
            // && (!form.coach || form.coach.length == 0 )
            let coachNotExist = false
            for (let i = 0; i < form.hourObject; i ++) {
                let hourObj = form.hourObject[i]
                if (hourObj.coach.length == 0) { coachNotExist = true; break; }
            }
            if (coachNotExist) { 
                Confirm("", "Please select coach")
                return;
            }
        }
        await addClassCart()
    }

    const addClassCart = async () => {
        // filter hourObj
        let newHourObj = []
        for (let i = 0; i < form.hourObject.length; i++) {
            let hourObj = form.hourObject[i]
            if (form.hours.indexOf(hourObj.hours[0]) >= 0) { 
                newHourObj.push(hourObj)
            }
        }
        let { data, error } = await addClassToCart({
            ...form,
            hourObject: newHourObj,
            classId: item._id,
            pax: number,
            totalPrice: (item.fee * number) * form.hours.length
        })
        if (error) { console.log(error) }
        if (data) { 
            setBooking(data);
        }
    }

    const paymentLogic = async () => {
        try {
            setLoading(true)
            let { data, error } = await submitOrderClass({
                classInfo: item._id,
                type: "class"
            })
            setLoading(false)
            if (error) { 
                // throw error 
                ConfirmationOneButton("", error ? error : "Something went wrong. Please try again.", "OK", () => {
                    reloadData()
                    getBookingsDateList()
                    getClassBooking()
                })
            }
            if (data) { navigate(`/payment/${data._id}/add-ons`) }
        } catch (error) {
            setLoading(false)
            Confirm("", error.message ? error.message : error)
        }
    }

    return (
        <>
            <div className="separation-line"/>
            <Grid sx={{
                pb: 16
            }}>
                {
                    disableView &&
                    <div style={{
                        zIndex: 1000,
                        position: 'absolute',
                        top: 180,
                        bottom: 150, left: 0, right: 0,
                        backgroundColor: '#f5f5f566'
                    }}/>
                }
                <div ref={myRef} style={{height: '0.5px', marginTop: 10, width: '100%'}}/>
                <Grid container p={2} pt={0} xs={12} pb={2} alignItems={"center"}>
                    <Grid item xs>
                        <TCLabel bold>Total Pax</TCLabel>
                    </Grid>
                    <Grid item xs>
                        <TCNumberInput
                            value={number}
                            maxNumber={maxValue()}
                            isFlex={true}
                            onChange={(e) => {
                                if (Number(e) < 1) { return; }
                                setNumber(e) 
                            } }
                        />
                    </Grid>
                </Grid>
                <Grid container padding={0} 
                    style={{marginTop: 0.5, paddingBottom: 20}} >
                    <Grid item xs={12}>
                        <Grid container>
                            {/* mesti check si user */}
                            <Grid item container justifyContent={"flex-start"} >
                                <Grid item xs={2.75} textAlign={"center"}>
                                    <div className={form.month === momentFormat(selectedDefaultDate, 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        onClick={() => {
                                            onChange({
                                                month: momentFormat(selectedDefaultDate, 'MM'),
                                                year: momentFormat(selectedDefaultDate, 'YYYY')
                                            })
                                            setSwipeState(0)
                                        }}
                                    >
                                        {momentFormat(selectedDefaultDate, 'MMM YY')}
                                    </div>
                                </Grid>
                                <Grid item xs={2.75} textAlign={"center"}>
                                    <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 1), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        onClick={() => {
                                            onChange({
                                                month: momentFormat(addMonths(selectedDefaultDate, 1), 'MM'),
                                                year: momentFormat(addMonths(selectedDefaultDate, 1), 'YYYY')
                                            })
                                            setSwipeState(1)
                                        }}
                                    >
                                        {momentFormat(addMonths(selectedDefaultDate, 1), 'MMM YY')}
                                    </div>
                                </Grid>
                                <Grid item xs={2.75} textAlign={"center"}>
                                    <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 2), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        onClick={() => {
                                            onChange({
                                                month: momentFormat(addMonths(selectedDefaultDate, 2), 'MM'),
                                                year: momentFormat(addMonths(selectedDefaultDate, 2), 'YYYY')
                                            })
                                            setSwipeState(2)
                                        }}
                                    >
                                        {momentFormat(addMonths(selectedDefaultDate, 2), 'MMM YY')}
                                    </div>
                                </Grid>
                                <Grid item xs={2.75} textAlign={"center"}>
                                    <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 3), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        onClick={() => {
                                            onChange({
                                                month: momentFormat(addMonths(selectedDefaultDate, 3), 'MM'),
                                                year: momentFormat(addMonths(selectedDefaultDate, 3), 'YYYY')
                                            })
                                            setSwipeState(3)
                                        }}
                                    >
                                        {momentFormat(addMonths(selectedDefaultDate, 3), 'MMM YY')}
                                    </div>
                                </Grid>
                                <Grid item xs={1} textAlign={"center"} justifyContent={"center"}>
                                    <div 
                                        // className={form.month === momentFormat(addMonths(new Date(), 3), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        className='calendar-custom-header'
                                        style={{fontSize: 15, padding: 0}}
                                        onClick={() => {
                                            setOpenCalendar(true)
                                        }}
                                    >
                                        <div style={{marginTop: 5}}>
                                            <Icon>eventicon</Icon>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <div {...handlers} style={{width: '100vw'}}>
                                <Calendar 
                                    className={"react-calendar"}
                                    onChange={(e) => {
                                        onChange({ selectedDate: toLocalGMT(e), date: toGMT7(e) });
                                    }}
                                    tileClassName={(({date}) => {
                                        if (moment(date).format("YYYY-MM-DD") < moment(new Date(item.classSchedule.endClassDate)).format("YYYY-MM-DD")) { return; }
                                        if (moment(date).format('YYYY-MM-DD') == moment(form.selectedDate).format('YYYY-MM-DD')) {
                                            return;
                                        }
                                    })}
                                    minDate={new Date(item.classSchedule.startClassDateString)}
                                    maxDate={new Date(item.classSchedule.endClassDateString)}
                                    defaultValue={form.selectedDate} 
                                    value={form.selectedDate}
                                    showNavigation={false}
                                    activeStartDate={new Date(form.year, (form.month - 1), 1)}
                                />
                            </div>
                            <div style={{border: '0.5px solid #3447671F', height: '0.5px', marginTop: 10, width: '100%'}}/>
                            {
                                availableHours.length > 0 &&
                                <Grid container item xs={12} md={12} lg={12} mb={16} style={{paddingLeft: 16, paddingRight: 16}}>
                                    <HoursComponent availableHours={availableHours} onChange={onChange} form={form} 
                                        navigate={navigate}
                                        number={number}
                                        maxValue={maxValue}
                                        isNotActiveCalendar={selectedDefaultDate >= getDate(user) || moment(new Date(form.selectedDate)) < moment(new Date('2024/05/03'))}
                                        disableJoinClass={disableJoinClass}
                                        isMyClass={isMyClass}
                                        classData={item}
                                    />
                                    {
                                        ((form.hours && form.hours.length > 0) && 
                                        (item.classSchedule.coaches && item.classSchedule.coaches.length > 0)) &&
                                        <Grid container xs={12} mt={1}>
                                            <TCLabel bold>Coach</TCLabel>
                                            {
                                                form.hours.sort().map( value => {
                                                    let currObjHours = null
                                                    for (let ho = 0; ho < form.hourObject.length; ho ++) {
                                                        let hObject = form.hourObject[ho]
                                                        if (hObject.hours.indexOf(value) >= 0) {
                                                            currObjHours = hObject
                                                        }
                                                    }
                                                    if (!currObjHours) { return }
                                                    return (
                                                        <>
                                                            <CoachComponent label={value} coachList={item.classSchedule.coaches} 
                                                                availableHours={availableHours} onChange={(e) => { onChangeHourObject(value, e) }} form={currObjHours} 
                                                                pax={number}
                                                            />
                                                        </>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    }
                                    {/* {
                                        (item.classSchedule.coaches &&item.classSchedule.coaches.length > 0) &&
                                        <CoachComponent coachList={item.classSchedule.coaches} 
                                            availableHours={availableHours} onChange={onChange} form={form} 
                                            pax={number}
                                        />
                                    } */}
                                </Grid>
                            }
                            {
                                availableHours.length <= 0 &&
                                <Grid container item xs={12} pt={-18}>
                                    <EmptyData marginTop={'20%'}/>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (!isMyClass && !disableJoinClass) &&
                    <TCDrawer
                        window={window}
                        isOpen={showBooking}
                        reRender={reRender}
                        titleText={"Class Summary"}
                        height={'90%'}
                        title={
                            <FooterAddSchedule
                                calculateSummary={calculateSummary}
                                booking={booking}
                                loading={loading}
                                checkUserisLoggedIn={checkUserisLoggedIn}
                                onAddBookings={onAddBookings}
                                setShowLogin={setShowLogin}
                                setShowBooking={setShowBooking}
                                setRerender={setRerender}
                                form={form}
                                isNotActiveCalendar={selectedDefaultDate >= getDate(user)}
                                checkAgeandGender={checkAgeandGender}
                            />
                        }
                        footer={
                            <div ref={bottomSheetRef}>
                                {
                                    (user && booking.length > 0) &&
                                    <BookingSummaryBottomSheet
                                        calculateSummary={calculateSummary}
                                        // valueoack
                                        user={user}
                                        booking={booking}
                                        setOpenModal={setOpenModal}
                                        setSelectValuePack={() => {}}
                                        // active deactive
                                        couponUsed={coupon != null}
                                        valuePackUsed={() => {}}
                                        loading={loading}
                                        setLoading={setLoading}
                                        //
                                        navigate={navigate}
                                    />
                                }
                                {/* Check title */}
                                {
                                    (calculateSummary().bundleUsed > 0) &&
                                    <Grid p={2} pb={1}>
                                        <ClassBundleRedeem data={item} totalSession={calculateSummary().totalClassBundle} />
                                        <Grid pt={1} textAlign={"right"}>
                                            <TCLabel>{calculateSummary().totalClassBundle} {calculateSummary().totalClassBundle > 1 ? "Sessions" : "Session"}</TCLabel>
                                            <TCLabel style={{color: color.pink}}><span>-</span>{calculateSummary().bundleUsed} {calculateSummary().bundleUsed > 1 ? "Sessions" : "Session"}</TCLabel>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container mt={1} justifyContent={"flex-end"} 
                                    style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                                        <TCLabel>Total {(calculateSummary().totalSession - calculateSummary().bundleUsed)} {(calculateSummary().totalSession - calculateSummary().bundleUsed) > 1 ? "Sessions" : "Session"}</TCLabel>
                                    </Grid>
                                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                                        <TCLabel style={{fontWeight: 'bold'}}>Total <span style={{fontWeight: 'bold'}}>{currencyFormat(calculateSummary().afterPrice)}</span></TCLabel>
                                    </Grid>
                                </Grid>
                                <Grid container mt={1} mb={2} justifyContent={"flex-end"} 
                                    style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Grid xs={12} md={12} lg={12} item style={{textAlign: 'center'}}>
                                        <MKButton color="primary" disabled={loading || booking.length <= 0}
                                            fullWidth onClick={() => { paymentLogic() }}>
                                            Pay
                                        </MKButton>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    >
                        <ClassBookingDetail bookingData={booking} deleteBookings={onDeleteBookings} 
                            onSelectBooking={() => {}}
                            bottomSheetHeight={bottomSheetHeight} 
                            loadUserCart={getClassBooking}
                        />
                    </TCDrawer>
                }
            </Grid>
            <SlideDialog
                isOpen={openCalendar} 
                setIsOpen={(e) => {
                    if (openCalendar) setOpenCalendar(e)
                }}
                useDialogContent={false}
                maxWidth={"lg"}
            >
                {
                    openCalendar &&
                    <MonthSelection selectedDate={form.selectedDate} 
                    onChangeCalendar={(e) => {
                        setSelectedDefaultDate(e)
                        setSwipeState(0)
                        setForm(prev => ({
                            ...prev,
                            selectedDate: toLocalGMT(e),
                            date: toGMT7(e),
                            month: toGMT7(e, 'MM'),
                            year: toGMT7(e, 'YYYY'),
                            hours: [],
                            totalSessions: 0,
                            totalPrice: 0,
                        }))
                    }}
                    closeCalendar={(e) => {
                        setOpenCalendar(e)
                    }} />
                }
            </SlideDialog>
            {
                loading &&
                <Loading/>
            }
        </>
    )
}

function HoursComponent ({
    classData,
    form,
    onChange,
    availableHours,
    isNotActiveCalendar,
    classIsFull,
    classIsNotAvailable,
    number,
    maxValue,
    disableJoinClass,
    isMyClass
}) {

    useEffect(() => {}, [form])

    useEffect(() => {
        let hourObj = form.hourObject
        for (let hindex = 0; hindex < hourObj.length; hindex ++) {
            let hObj = hourObj[hindex]
            let coach = checkCoach(hObj.coach, hObj.hours)
            hObj.coach = coach
        }
        onChange({hourObject: hourObj})
    }, [form.date])

    const [ showPlayerList, setShowPlayerList ] = useState({
        data: null,
        open: false
    })

    const updateSelectedTime = (value) => {
        let index = form.hours.indexOf(value.label);
        let newForm = form.hours;
        let sessions = form.totalSessions;
        let total = form.totalPrice;
        let hourObject = form.hourObject.sort((a, b) => a.hours > b.hours ? 1 : -1);
        if (value.active && index < 0) {
            newForm = [ ...form.hours, value.label ];
            sessions += 1;
            total += value.nominee;
            if (classData.classSchedule.coaches && classData.classSchedule.coaches.length > 0) {
                hourObject.push({
                    selectedDate: form.selectedDate,
                    date: form.date,
                    month: form.month,
                    year: form.year,
                    court: null,
                    hours: [value.label],
                    coach: [],
                    totalSessions: 1,
                    totalPrice: value.nominee,
                })
            }
        } else if (index >= 0) {
            newForm.splice(index, 1);
            if (classData.classSchedule.coaches && classData.classSchedule.coaches.length > 0) {
                hourObject.splice(index, 1);
            }
            sessions -= 1;
            total -= value.nominee;
        }

        let court = null
        if (classData.classSchedule && (classData.classSchedule.courtsInfo && classData.classSchedule.courtsInfo.length > 0)) {
            for (let i = 0; i < classData.classSchedule.courtsInfo.length; i ++) {
                let courtsInfo = classData.classSchedule.courtsInfo[i]
                let hoursExist = false
                if (courtsInfo.date == form.date) {
                    for (let j = 0; j < newForm.length; j++) {
                        if (courtsInfo.hours.indexOf(newForm[j]) >= 0 ) {
                            hoursExist = true;
                            break;
                        }
                    }
                }
                if (hoursExist) {
                    court = courtsInfo.courtId
                }
            }
        }

        let coach = []
        if (classData.classSchedule.coaches && classData.classSchedule.coaches.length > 0) {
            for (let hindex = 0; hindex < hourObject.length; hindex ++) {
                let hObj = hourObject[hindex]
                coach = checkCoach(hObj.coach, hObj.hours)
                hObj.coach = coach
            }
        }

        onChange({court, hourObject, coach, hours: newForm, totalSessions: sessions, totalPrice: total})
    }

    const checkCoach = (coach, hours) => {
        let { classSchedule } = classData
        if (hours.length <= 0) { return [] }
        if (!classSchedule.coaches) { return [] }
        if (number < coach.length) { coach = [form.coach[0]] }
        if ((!coach || coach.length == 0) && (form.backendHourObject && form.backendHourObject.length > 0)) {
            for (let i = 0; i < form.backendHourObject.length; i ++) {
                console.log('here backendHourObject', form.backendHourObject[i], hours, form.backendHourObject[i].hours)
                if (hours[0] == form.backendHourObject[i].hours[0] && form.date == form.backendHourObject[i].date) {
                    coach = form.backendHourObject[i].coach
                }
            }
        }
        console.log('here localHourObject', form.localHourObject)
        if ((!coach || coach.length == 0) && (form.localHourObject && form.localHourObject.length > 0)) {
            for (let i = 0; i < form.localHourObject.length; i ++) {
                console.log('here localHourObject', form.date, form.localHourObject[i].date, hours, form.localHourObject[i].hours)
                if (hours[0] == form.localHourObject[i].hours[0] && form.date == form.localHourObject[i].date) {
                    coach = form.localHourObject[i].coach
                }
            }
        }

        console.log('here backendHourObject', hours, form.hourObject, form.backendHourObject)
        
        let availableCoach = null
        for (let i = 0; i < classSchedule.coaches.length; i ++) {
            let coaches = classSchedule.coaches[i]
            let isAvailable = checkCoachAvaiable(coaches, hours, availableHours)
            let isWaitingPayment = checkCoachWaitingPayment(coaches, hours || [], availableHours)

            if (isWaitingPayment) { isAvailable = false; }
            if (isAvailable) { availableCoach = coaches }
            // if (isAvailable && coach.indexOf(coaches._id.toString() >= 0)) { return coach }
            if (isAvailable && (coach && coach.length == 0)) { 
                coach = [coaches._id.toString()] 
            } else if (!isAvailable && coach.indexOf(coaches._id.toString()) >= 0) { 
                if (availableCoach) { coach = [availableCoach._id.toString()] } 
                else { coach = [] }
            } else if (!isAvailable && availableCoach) { coach = [availableCoach._id.toString()]
            } else if (!isAvailable) { coach = [] }
        }
        return coach
    }

    const checkParticipant = (value, hours) => {
        if (classData) {
            let { classParticipant } = classData
            let participantList = []
            for (let i = 0; i < classParticipant.length; i ++) {
                if (classParticipant[i].date == form.date && classParticipant[i].hours.indexOf(hours) >= 0) {
                    participantList.push(classParticipant[i])
                }
            }
            if (participantList.length > 0) {
                setShowPlayerList({
                    data: {
                        ...classData,
                        hours: [hours],
                        participant: participantList,
                        selectedDate: form.date,
                        hoursComponentData: value
                    },
                    open: true
                })
            }
        }
    }

    return (
        <Grid container>
            <Grid mb={1} item>
                <TCLabel bold>Time</TCLabel>
            </Grid>
            <Grid container spacing={0.8}>
            {
                availableHours.map( value => {
                    if ((number > 1 && ((maxValue() - value.participant) < number))
                        || (number > 1 && ((maxValue() - value.totalWaitingPayment) < number))
                    ) {
                        let indexSimiliar = form.hours.indexOf(value.label)
                        let newHours = form.hours
                        if (form.hours.length > 0 && indexSimiliar >= 0) { 
                            newHours.splice(indexSimiliar, 1)
                            let totalPrice = 0
                            for (let i = 0; i < newHours.length; i ++) {
                                totalPrice += (newHours[i].nominee * number)
                            }
                            onChange({hours: newHours, totalSessions: newHours.length, totalPrice: 0}) 
                        }
                    }
                    return (
                        <Grid item>
                            <div className={
                                    // (number > 1 && ((maxValue() - value.participant) < number)) ? 'selection-disabled' :
                                    (number > 1 && ((maxValue() - value.totalWaitingPayment) < number)) ? 'selection-idle' :
                                    value.offDays ? 'selection-disabled' :
                                    value.isFull ?  'selection-disabled' :
                                    !value.isAvailable ? 'selection-disabled' :
                                    (!value.coachAvailable && (value.waitingPaymentCoach && value.waitingPaymentCoach.length > 0)) ? 'selection-idle' :
                                    !value.coachAvailable ? 'selection-disabled' :
                                    !value.isAvailableHours ? 'selection-disabled' :
                                    value.isWaitingPayment ? 'selection-idle' :
                                    value.coachWaitingPayment ? 'selection-idle' :
                                    !value.active ? 'selection-disabled' :
                                    classIsFull ?  'selection-disabled' :
                                    classIsNotAvailable ?  'selection-disabled' :
                                    isNotActiveCalendar ? 'selection-disabled' :
                                    value.tournamentBooked ? 'selection-booked-others' : 
                                    value.idle ? 'selection-idle' : 
                                    value.booked ? value.ownBooked ? 'selection-booked-own' : 
                                    value.classBooked ? 'selection-booked-own' : 'selection-booked' : 
                                    value.active ? 
                                        form.hours.indexOf(value.label) >= 0 ? 
                                        'selection-selected' : 
                                        'selection-available' 
                                    : 'selection-disabled'
                                } 
                                style={{textAlign: 'center', padding: 5, minHeight: 50, borderRadius: 8}}
                                onClick={() => {
                                    if (disableJoinClass) { return; }
                                    if ((number > 1 && ((maxValue() - value.participant) < number))) { 
                                        Confirm("", `Only ${maxValue() - value.participant} pax available`)
                                        return;
                                    }
                                    if ((number > 1 && ((maxValue() - value.totalWaitingPayment) < number))
                                        || (!value.coachAvailable && (value.waitingPaymentCoach && value.waitingPaymentCoach.length > 0))
                                    ) { 
                                        Confirm("", `Awaiting payment. Please check again`)
                                        return;
                                    }
                                    if (isNotActiveCalendar) { return; }
                                    if (isMyClass) { checkParticipant(value, value.label); return; }
                                    if (value.offDays) { Confirm("", "Off"); return; }
                                    if (!value.coachAvailable || !value.active || !value.isAvailable || !value.isAvailableHours) {
                                        if (isMyClass) { Confirm("", "Not Available"); return; }
                                        else { return; }
                                    }
                                    if (value.isFull) { Confirm("", "Full"); return; }
                                    if (value.isWaitingPayment) { Confirm("", `Awaiting payment. Please check again`); return; }
                                    if (value.coachWaitingPayment) { Confirm("", `Awaiting payment. Please check again`); return; }
                                    if (value.idle || value.booked) { 
                                        Confirm("", value.idle ? "Awaiting payment. Please check again" : value.ownBooked ? "My booking" 
                                        : value.classBooked ? "Class Schedule" : value.tournamentBooked ?  `${getEventName(value)}` : "Booked")
                                        return; 
                                    }
                                    if (classIsFull) { Confirm("", "Full"); return; }
                                    if (classIsNotAvailable) { Confirm("", "Class session not available"); return; }
                                    if (!value.isAvailable) { Confirm("", "Class session not available"); return; }
                                    if (value.isFull) { Confirm("", "Full"); return; }
                                    // if (value.isWaitingPayment) { Confirm("", "Awaiting payment. Please check again"); return; }
                                    updateSelectedTime(value)
                                }}
                            >
                                <div>{value.label}</div>
                                {
                                    value.offDays ? <div style={{minWidth: 50}}>{"Off"}</div>
                                    :
                                    (!isMyClass && !value.isAvailableHours) ? <div style={{minWidth: 50}}></div>
                                    :
                                    (value.coachWaitingPayment || value.isWaitingPayment) ?
                                    <div>{value.maxParticipant}•{value.participant || 0} going</div>
                                    :
                                    !value.coachAvailable || !value.courtExist ? <div style={{
                                        paddingTop: 2
                                    }}>
                                        <div style={{minWidth: 50, fontSize: 8}}>{"Not Available"}</div>
                                    </div>
                                    :
                                    <div>{value.maxParticipant}•{value.participant || 0} going</div>
                                }
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
            <SlideDialog
                isOpen={showPlayerList.open} 
                setIsOpen={(e) => {
                    setShowPlayerList({
                        data: null,
                        open: e
                    })
                }}
                useDialogContent={false}
                maxWidth={"lg"}
            >
                {
                    showPlayerList.open &&
                    <PlayersList closeDialog={() => { setShowPlayerList({
                        open: false,
                        data: null
                    })}} data={showPlayerList.data}/>
                }
            </SlideDialog>
        </Grid>
    )
}

function CoachComponent ({
    label,
    availableHours,
    onChange,
    form,
    coachList,
    pax
}) {

    console.log('here form info', form)

    useEffect(() => {
        console.log('here label and pax', label, pax)
        if (pax < form.coach.length) { onChange({coach: [form.coach[0]]}) }
    }, [pax])

    const onSelectCoach = (value, i) => {
        let index = form.coach.indexOf(value._id);
        let newCoach = form.coach
        if (index < 0) {
            // [ ...form.coach, value._id.toString() ]
            if (pax > 1) { newCoach.splice(i, 0, value._id.toString()) }
            else { newCoach = [ value._id.toString() ] }
        } else if (index >= 0 && form.coach.length > 1) {
            newCoach.splice(index, 1);
        }
        onChange({coach: newCoach})
    }

    return (
        <Grid container alignItems={"center"} justifyItems={"center"} mt={1}>
            <Grid item container xs={1.5} alignItems={"center"} justifyItems={"center"}>
                <TCLabel bold>{label ? label : Coach}</TCLabel>
            </Grid>
            <Grid item xs container spacing={0.8}>
            {
                coachList.map( (value, index) => {
                    let isAvailable = checkCoachAvaiable(value, [label] || [], availableHours)
                    let isWaitingPayment = checkCoachWaitingPayment(value, [label] || [], availableHours)
                    return (
                        <Grid item>
                            <div className={
                                    isWaitingPayment ? 'selection-idle' :
                                    !isAvailable ? 'selection-disabled' :
                                    (form.coach && form.coach.indexOf(value._id.toString()) < 0) ? 'selection-available' :
                                    (form.coach && form.coach.indexOf(value._id.toString()) >= 0) ? 'selection-selected' :
                                    isAvailable ? 'selection-available' :
                                    'selection-disabled'
                                } 
                                style={{ padding: 5, minWidth: 60, minHeight: 20, borderRadius: 8,
                                     display: 'flex', justifyItems: 'center', alignItems: 'center'}}
                                onClick={() => {
                                    if (!form.hours || form.hours.length <= 0) { return; }
                                    if (isAvailable) { onSelectCoach(value, index) }
                                    if (isWaitingPayment) { 
                                        Confirm("", `Awaiting payment. Please check again`)
                                        return;
                                    }
                                }}
                            >
                                <div style={{
                                    width: '100%',
                                    textAlign: 'center',
                                }}>{value.name}</div>
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Grid>
    )
}

function getDate(user) {
    return new Date(moment(addMonths(new Date(), user ? user.advancedSchedule ? user.advancedSchedule : (user.activeBenefits && user.activeBenefits.advancedMonths) ? user.activeBenefits.advancedMonths : 1 : 1)).endOf('month'))
}

function FooterAddSchedule ({
    calculateSummary,
    booking,
    loading,
    checkUserisLoggedIn,
    onAddBookings,
    // saveLocalBookingForm,
    form,
    setShowLogin,
    setShowBooking,
    setRerender,
    isNotActiveCalendar,
    checkAgeandGender
}) {

    return (
        <>
            <Grid container mt={2} justifyContent={"flex-end"} 
                style={{paddingLeft: 16, paddingRight: 16}}>
                <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                    <TCLabel bold>
                        <TCLabel>Total {calculateSummary().totalSession} {calculateSummary().totalSession > 1 ? "Sessions" : "Session"}</TCLabel>
                    </TCLabel>
                </Grid>
                <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                    <TCLabel bold>
                        {`Total ${currencyFormat(calculateSummary().totalPrice)}`}
                    </TCLabel>
                </Grid>
            </Grid>
            <Grid container mb={0} pb={2} justifyContent={"flex-end"} 
                style={{
                    backgroundColor:'white',
                    paddingLeft: 16, paddingRight: 16 }}
                onTouchStart={(e) => { e.stopPropagation(true) }}
            >
                <Grid mt={1} xs={2} md={2} lg={2} item style={{textAlign: 'center'}}>
                    <MKButton color="white" 
                        disabled={loading || isNotActiveCalendar || checkAgeandGender()} 
                        fullWidth
                        onClick={() => {
                            // conditional check
                            let loggedIn = checkUserisLoggedIn();
                            if (loggedIn) { onAddBookings() } else {
                                // saveLocalBookingForm(form)
                                setShowLogin(true)
                            }
                        }}
                        sx={{
                            border: 1,
                            color: color.primary
                        }}
                    >
                        <Icon>more_time</Icon>
                        <span style={{marginTop: 2}}>{"Add"}</span>
                    </MKButton>
                </Grid>
                <Grid mt={1} pl={2} xs={10} md={10} lg={10} item style={{textAlign: 'center'}}>
                    <MKButton 
                        color="primary" 
                        disabled={loading || booking.length <= 0 || checkAgeandGender()}
                        fullWidth 
                        onClick={() => {
                            setShowBooking(true)
                            setRerender(Date.now())
                        }}
                        sx={{ backgroundColor: color.primary }}>
                        Checkout
                    </MKButton>
                </Grid>
            </Grid>
        </>
    )
}

function ClassBundleRedeem ({
    data, totalSession
}) {

    return (
        <div style={{display:'flex', alignItems: 'flex-end' }}>
            <div style={{width: '100%'}}>
                <div className="">
                    <div style={{borderStyle: 'solid', 
                        borderColor: color.green,
                        borderRadius: 10,
                    }}>
                        <Grid container alignItems={"center"} pl={1} pr={1} pt={0.2} pb={0.2}>
                            <Grid item xs={1} md={1} alignItems={"center"}>
                                <img src={ChecklistIcon} style={{width: 30, height: 30, marginTop: 10}}/>
                            </Grid>
                            <Grid item xs={9} md={9} pl={1}>
                                {/* <div><TCLabel bold>{data.selectedSession} {data.selectedSession > 1 ? "Sessions" : "Session"}</TCLabel></div> */}
                                <div><TCLabel bold>Class bundle redeemed</TCLabel></div>
                                <div><TCLabel subtitle>{(totalSession - data.bundle) > 0 ? data.bundle : totalSession} {totalSession > 1 ? "Sessions" : "Session"}</TCLabel></div>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Box textAlign={"right"}>
                                    {/* <div onClick={(e) => {
                                        e.stopPropagation()
                                        cancelValuePackUsage()
                                    }}>
                                        <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                    </div> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PlayersList ({
    data,
    closeDialog
}) {

    const [ showQR, setShowQR ] = useState({
        open: false
    })

    let selectedDate = data ? data.selectedDate : null
    let hoursComponentData = data ? data.hoursComponentData : null
    let endTime = data.hours[data.hours.length - 1]
    let endTimeNumber = Number(endTime.split(":")[0]) + 1
    let endTimeTime = `${endTimeNumber > 9 ? '' : '0'}${endTimeNumber}:00`

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const isCoach = (data.coach && userInfo) ? data.coach.indexOf(userInfo._id) >= 0 : false
    console.log('playerList', data)
    let dateList = data.classSchedule ? data.classSchedule.dates : null

    return (
        <Grid container p={2}>
            <DialogHeader title={<TCLabel bold style={{fontSize: 15}}>Participants</TCLabel>} closeDialog={()=> { closeDialog() }}/>
            <>
                <Grid style={{marginTop: 5}} xs={12} sx={{
                    border: `0.5px solid ${color.primary}`,
                    borderRadius: 2
                }} justifyContent={"top"}>
                    <div className='custom-body' style={{padding: 10}}>
                        <>
                            <div style={{display: 'flex', alignItems: "top", marginTop: 2}}>
                                {
                                    dateList ?
                                    <div style={{width: '100%'}}>
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{moment(dateList[dateList.indexOf(selectedDate)]).format("ddd DD/MM/YYYY")}</TCLabel>
                                        {/* <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{courtTypeObj[data.courtInfo.courtType]} {data.courtInfo.name}</TCLabel> */}
                                        <TCLabel style={{fontSize: 12}}>{`${data.hours[0]} - ${endTimeTime}`}</TCLabel>
                                        {/* <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{currencyFormat(v.total)}</span></TCLabel> */}
                                    </div>
                                    :
                                    <div style={{width: '100%'}}>
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{moment(data.selectedDate).format("ddd DD/MM/YYYY")}</TCLabel>
                                        {/* <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{courtTypeObj[data.courtInfo.courtType]} {data.courtInfo.name}</TCLabel> */}
                                        <TCLabel style={{fontSize: 12}}>{`${data.hours[0]} - ${endTimeTime}`}</TCLabel>
                                        {/* <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{currencyFormat(v.total)}</span></TCLabel> */}
                                    </div>
                                }
                                <div>
                                <Grid className={
                                    (data.participant.length <= 0 || (hoursComponentData && !hoursComponentData.isAvailableHours)) ? 
                                    "element-disabled element-1-disabled" : "element element-1"
                                } xs={12}
                                    sx={{
                                        color: '#ffff',
                                        width: 40,
                                        height: 40,
                                        mt: 0.5
                                    }} 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (dateList && data.participant.length <= 0) {
                                            return;
                                        }
                                        if (hoursComponentData && !hoursComponentData.isAvailableHours) {
                                            return;
                                        }
                                        console.log('here check barcode data', data.participant[0], data.hours)
                                        console.log('here check barcode data', data.participant[0].courtsInfo)
                                        let courtInfo = data.participant[0].courtsInfo[0]
                                        for (let i = 0; i < data.participant[0].courtsInfo.length; i ++) {
                                            if (data.participant[0].courtsInfo[i].hours[0] == data.hours[0]) {
                                                courtInfo = data.participant[0].courtsInfo[i];
                                                break;
                                            }
                                        }
                                        // let courtInfo = 
                                        setShowQR({
                                            open: true,
                                            courtInfo: {
                                                ...courtInfo.court,
                                                hours: courtInfo.hours,
                                                date: courtInfo.date
                                            },
                                            data: {
                                                ...data,
                                                // eventName: orderInfo.classObj.name,
                                                date: courtInfo.date,
                                                hours: courtInfo.hours,
                                            },
                                            uniqueCode: courtInfo.uniqueCode,
                                            value: data.barcode
                                        })
                                    }
                                }>
                                    <Icon fontSize={'large'}>qr_code_2</Icon>
                                </Grid>
                                </div>
                            </div>
                        </>
                    </div>
                </Grid>
                <Grid xs={12} mt={0.5}>
                    {
                        data.participant && data.participant.map( value => {
                            return (
                                <UserCard key={value._id} value={value.user} hideClub={false} classInfo={value} classCard={true} showContact={(data.createdBy && !isCoach) ? true : false}/>
                            )
                        })
                    }
                </Grid>
            </>
            <SlideAlert
                isOpen={showQR.open} 
                setIsOpen={() => {
                    if (showQR.open) {
                        setShowQR(prev => ({
                            ...prev,
                            open: false
                        }))
                    }
                }}
                maxWidth={"xs"}
            >
                {
                    showQR.open &&
                    <QRPage courtInfo={showQR.courtInfo} qrValue={showQR.uniqueCode} orderInfo={showQR.data} />
                }
            </SlideAlert>
        </Grid>
    )
}

const checkCoachAvaiable = (e, hours, availableHours) => {
    if (!hours || hours.length == 0) { return true }
    console.log('here isWaitingPayment', e, hours, availableHours)
    for (let i = 0; i < availableHours.length; i ++) {
        if (hours.indexOf(availableHours[i].label) >= 0 &&
            availableHours[i].active == true && 
            availableHours[i].coachAvailableList.indexOf(e.name) < 0) {
            return false
        }
    }
    return true
}

const checkCoachWaitingPayment = (e, hours, availableHours) => {
    if (!hours || hours.length == 0) { return false }
    for (let i = 0; i < availableHours.length; i ++) {
        if (!(availableHours[i].waitingPaymentCoach)) { continue; }
        if (hours.indexOf(availableHours[i].label) >= 0 &&
            (availableHours[i].waitingPaymentCoach.indexOf(e.name) >= 0) || 
            availableHours[i].waitingPaymentCoach.indexOf(e._id) >= 0) {
            return true
        }
    }
    // if (form.coach.length) { onChange({coach: e._id}) }
    return false
}

const getBookingObject = (
    date,
    booking,
    item
) => {

    let bookingInfo = {
        hours: [],
        pax: 0,
        totalSessions: 0,
        totalPrice: 0,
        hourObject: []
    }

    let newForm = null
    let hours = []
    let hourObj = []
    for (let i = 0; i < booking.length; i ++) {
        if (date == booking[i].date) {
            hours = hours.concat(booking[i].hours)
            newForm = booking[i]
            if (item.classSchedule.coaches && item.classSchedule.coaches.length > 0) { 
                hourObj.push({
                    hours: booking[i].hours,
                    pax: booking[i].pax,
                    totalSessions: booking[i].totalSessions,
                    totalPrice: booking[i].totalPrice,
                    coach: booking[i].coach || []
                })
            } else {
                bookingInfo = {
                    hours: booking[i].hours,
                    pax: booking[i].pax,
                    totalSessions: booking[i].totalSessions,
                    totalPrice: booking[i].totalPrice,
                    coach: booking[i].coach || []
                }
                break;
            }
        }
    }
    if (newForm) {
        bookingInfo = {
            ...newForm,
            hours,
            hourObject: hourObj.sort((a, b) => a.hours > b.hours ? 1 : -1)
        }
    }
    return bookingInfo
}

export default ClassDetailv2Component;