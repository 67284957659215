import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { addDays, addMonths, subDays } from 'date-fns';

import Grid from "@mui/material/Grid";
import MKButton from 'components/MKButton';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';

import { getCourtInfo } from 'helper/localStorage';
import TCDrawer from 'components/TopCourtComponents/Drawer/TopCourtDrawer';
import { useSwipeable } from 'react-swipeable';

import Calendar from 'react-calendar';
import { currencyFormat } from 'helper/numberFormat';
import moment from 'moment';
import { Box, Card, Checkbox, Dialog, DialogContent, FormControlLabel, Icon, Modal } from '@mui/material';
import BookingDetail from './BookingDetail';

import { Hours, color, isPhone, courtType, centerBox } from 'helper/constant';
import { getCourtsDetail, getCourtsSchedule, getUserCart, loadMe,
    addCart, paymentLogic, deleteCart, checkUserisLoggedIn, 
    saveLocalBookingForm, loadLocalBookingForm, removeLocalBookingForm, loadUserCart, cancelValuePackUse, 
    getSelectedCourtSchedule,
    getCourtsDetailv2} from './Function';
import { getScheduleList } from 'service/orderService';
// import SlideAlert from 'components/SlideInAlert/SlideAlert';

import Login from '../Auth/Login';
import Loading from 'components/TopCourtComponents/Loading/TCLoadingComponent';
import { TCTitleLabel, TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
// import { TCInput } from 'components/TopCourtComponents/Input/TopCourtInput';
// import Coupon from 'components/TopCourtComponents/Coupon/Coupon';
import { Confirm } from 'helper/showAlert';
import BookingPackSelection from './BookingPackSelection';

import PopupValuePack from './PopupValuePack';
import BookingSummaryBottomSheet from './BookingSummaryBottomSheet';
import { toGMT7, toLocalGMT, momentFormat } from 'helper/time';
import SlideDialog from 'components/SlideDialog/SlideDialog';
import MonthSelection from './MonthSelection';
import SlideAlert from 'components/SlideInAlert/SlideAlert';
import ImagesDetail from 'components/TopCourtComponents/Images/ImagesDetail';
import { loadImages } from 'helper/loadImages';
import { ConfirmActionBody } from 'helper/showAlert';

import addOnSVG from "../../assets/addon.svg";
import { forceGMT7 } from 'helper/time';

function BookingCourt ({

}) { 

    const navigate = useNavigate()
    const courtInfoJSON = getCourtInfo ? JSON.parse(getCourtInfo) : null
    const windowLocationHash = window.location.hash ? window.location.hash.substring(1) : null
    window.location.hash = ""

    const [selectedDefaultDate, setSelectedDefaultDate ] = useState(new Date())

    const [ courtInfo, setCourtInfo ] = useState(courtInfoJSON)
    const [ swipeState, setSwipeState ] = useState(0)
    const [ selectValuePack, setSelectValuePack] = useState(false)

    const [ courts, setCourts ] = useState([])
    const [ availableHours, setAvailableHours ] = useState(Hours)

    const [ hourList, setHourList ] = useState([])

    const [ form, setForm ] = useState({
        selectedDate: windowLocationHash ? toLocalGMT(new Date(windowLocationHash)) : toLocalGMT(new Date()),
        date: windowLocationHash ? windowLocationHash: toGMT7(new Date()),
        month: windowLocationHash ? toGMT7(new Date(windowLocationHash), 'MM') : toGMT7(new Date(), 'MM'),
        year: windowLocationHash ? toGMT7(new Date(windowLocationHash), 'YYYY') : toGMT7(new Date(), 'YYYY'),
        court: null,
        hours: [],
        totalSessions: 0,
        totalPrice: 0,
        rerender: Date.now()
    })

    const [ user, setUser ] = useState(null)
    const [ booking, setBooking ] = useState([])
    const [ couponCode, setCouponCode ] = useState("")
    const [ coupon, setCoupon ] = useState(null)
    const [ reRender, setRerender ] = useState(Date.now)
    const [ showBooking, setShowBooking ] = useState(false);
    const [ showLogin, setShowLogin ] = useState(false);

    const [ bottomSheetHeight, setBottomSheetHeight] = useState(null)
    const [ loading, setLoading ] = useState(true);

    const [ openModal, setOpenModal ] = useState(false);
    const [ openCalendar, setOpenCalendar ] = useState(false);
    const [ openPackModal, setOpenPackModal ] = useState({
        open: false,
        months: 0
    });
    const [ packUsageInfo, setPackUsageInfo ] = useState({
        selectedSession: 0,
        totalSession: 0,
        totalBefore: 0,
        totalOrder: 0,
    })

    useEffect(() => {
        if (courtInfo && courtInfo._id) { loadCourts() }
    }, [courtInfo])

    // useEffect(() => {
    //     function getAlerts() {
    //         // getBookingsDateList()
    //         getCourtsSchedule(form.court, form.date, setAvailableHours, setLoading);
    //     }
    //     const interval = setInterval(() => getAlerts(), 5000)
    //     return () => {
    //         clearInterval(interval);
    //     }
    // }, [])

    useEffect(() => {
        // check local form
        // console.log('here check wrong gmt', form)
        let localForm = loadLocalBookingForm
        if (localForm) {
            let parsedLocalForm = JSON.parse(localForm)
            // check current Date
            // console.log(new Date().getDate())
            // console.log(new Date(parsedLocalForm.date).getDate())
            if (new Date().getDate() > new Date(parsedLocalForm.date).getDate()) {
                removeLocalBookingForm();
                return;
            }
            setForm(prev => ({
                ...prev,
                date: parsedLocalForm.date,
                month: parsedLocalForm.month,
                year: parsedLocalForm.year,
                court: parsedLocalForm.court
            }))
            addCart(parsedLocalForm, setBooking, setLoading)
        }
        loadMe(setUser)
        getBookingsDateList()
        getUserCartSummaryInfo()
    }, [])

    useEffect(() => {
        if (!user) { loadMe(setUser) }
    }, [booking])

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (swipeState == 3) { return; }
            setSwipeState(swipeState + 1)

            // let monthPlus1 = ( 30 * (swipeState + 1)) + 1
            // let month = moment(addDays(new Date(), monthPlus1)).format('MM')
            // let year = moment(addDays(new Date(), monthPlus1)).format('YYYY')
            let monthPlus1 = swipeState + 1
            // let month = momentFormat(addMonths(new Date(), monthPlus1), 'MM')
            // let year = momentFormat(addMonths(new Date(), monthPlus1), 'YYYY')
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
        onSwipedRight: () => {
            if (swipeState == 0) { return; }
            setSwipeState(swipeState - 1)

            // let monthMin1 = ( 30 * (swipeState - 1)) + 1
            // let month = moment(addDays(new Date(), monthMin1)).format('MM')
            // let year = moment(addDays(new Date(), monthMin1)).format('YYYY')

            let monthPlus1 = swipeState - 1
            // let month = momentFormat(addMonths(new Date(), monthPlus1), 'MM')
            // let year = momentFormat(addMonths(new Date(), monthPlus1), 'YYYY')
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
    });

    const getUserCartSummaryInfo = async () => {
        try {
            let { data, error } = await loadUserCart()
            if (data) { 
                console.log('here data load user cart', data)
                setPackUsageInfo({
                    totalBefore: data.subTotal,
                    totalOrder: data.totalPrice,
                    selectedSession: data.totalSessionRedeemed,
                    totalSession: data.totalSessions
                })
                let { totalSessionRedeemed } = data
                setSelectValuePack(totalSessionRedeemed && totalSessionRedeemed > 0 ? true : false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const checkBookingHours = (date, court) => {
        let dateString = toGMT7(date, 'YYYY-MM-DD')
        court = form.court
        let bookingInfo = {
            hours: [], 
            totalSessions: 0,
            totalPrice: 0
        }
        for (let i = 0; i < booking.length; i++) {
            let courtId = (booking[i].court && booking[i].court._id) ? booking[i].court._id.toString() : booking[i].court.toString()
            if (booking[i].date == dateString && courtId == court.toString()) {
                // hours = booking[i].hours;
                bookingInfo.hours = booking[i].hours
                bookingInfo.totalSessions = booking[i].totalSessions
                bookingInfo.totalPrice = booking[i].totalPrice
                break;
            }
        }
        return bookingInfo
    }

    useEffect(() => {
        if (form.court) { 
            setLoading(true)
            getCourtsSchedule(form.court, form.date, setAvailableHours, setLoading);
            getUserCart(setBooking, setLoading)
        }
    }, [form.rerender, form.court])

    useEffect(() => {
        if (form.date) {
            getSelectedCourtSchedule(form.date, setForm, setLoading)
        }
    }, [form.date])

    useEffect(() => {
        let bookingHours = checkBookingHours(new Date(form.date))
        onChange({
            ...bookingHours
        });
    }, [booking])

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const loadCourts = async () => {
        try {
            getCourtsDetailv2(courtInfo, setCourts)
        } catch (error) {
            console.log(error)
        }
    }

    const getBookingsDateList = async (e) => {
        try {
            let { data, error } = await getScheduleList()
            if (error) { console.log(error) }
            if (data) { setHourList(data) }
        } catch (error) {
            console.log(error)
        }
    }

    const onAddBookings = (e) => {
        if (!form.date || form.hours.length == 0) {
            Confirm("", "Please select session time")
            return;
        }
        if (selectValuePack) {
            cancelValuePackUse(setPackUsageInfo, setSelectValuePack)
        }
        if (coupon) { 
            setCouponCode(""); setCoupon(null)
        }
        addCart(form, setBooking, setLoading)
    }

    const onDeleteBookings = (e) => {
        if (selectValuePack) {
            cancelValuePackUse(setPackUsageInfo, setSelectValuePack)
        }
        if (coupon) { 
            setCouponCode(""); setCoupon(null)
        }
        deleteCart(e._id, e, setBooking, setLoading)
    }

    const calculateSummary = () => {
        let totalSession = 0;
        let totalPrice = 0;
        let totalPack = user ? user.sessions : 0;

        booking.map( value => {
            if ((value.hours && value.hours.length > 0) || value.totalSessions > 0) {
                totalSession += value.totalSessions
                totalPrice += value.totalPrice
            }
        })
        if (coupon && coupon.amount) {
            totalPrice -= coupon.amount
        }
        if (selectValuePack && (totalSession >= totalPack)) {
            console.log(true)
        } else {
            totalPack = totalSession
        }
        return { totalSession, totalPrice, totalPack }
    }

    const onSelectBooking = (e) => {
        setShowBooking(false)
        setRerender(Date.now())
        let courtId = (e.court && e.court._id) ? e.court._id.toString() : e.court.toString()
        setForm(prev => ({
            ...prev,
            court: courtId,
            selectedDate: toLocalGMT(e.date),
            date: toGMT7(new Date(e.date)),
            month: toGMT7(new Date(e.date),'MM'),
            year: toGMT7(new Date(e.date),'YYYY'),
        }))
    }

    const bottomSheetRef = useCallback(n => {
        if (n !== null) {
            setBottomSheetHeight(n.getBoundingClientRect().height)
        }
    })

    useEffect(() => {
    }, [showLogin])

    return (
        <>
            <DefaultNavbar sticky showLogin={showLogin} setShowLogin={setShowLogin} />
            <Grid container padding={0} 
                style={{marginTop: 0.5, paddingBottom: 20}} >
                <Grid item xs={12}>
                    <Grid container>
                        {/* mesti check si user */}
                        <Grid item container justifyContent={"flex-start"} >
                            <Grid item xs={2.75} textAlign={"center"}>
                                <div className={form.month === momentFormat(selectedDefaultDate, 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                    onClick={() => {
                                        onChange({
                                            month: momentFormat(selectedDefaultDate, 'MM'),
                                            year: momentFormat(selectedDefaultDate, 'YYYY')
                                        })
                                        setSwipeState(0)
                                    }}
                                >
                                    {momentFormat(selectedDefaultDate, 'MMM YY')}
                                </div>
                            </Grid>
                            <Grid item xs={2.75} textAlign={"center"}>
                                <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 1), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                    onClick={() => {
                                        onChange({
                                            month: momentFormat(addMonths(selectedDefaultDate, 1), 'MM'),
                                            year: momentFormat(addMonths(selectedDefaultDate, 1), 'YYYY')
                                        })
                                        setSwipeState(1)
                                    }}
                                >
                                    {momentFormat(addMonths(selectedDefaultDate, 1), 'MMM YY')}
                                </div>
                            </Grid>
                            <Grid item xs={2.75} textAlign={"center"}>
                                <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 2), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                    onClick={() => {
                                        onChange({
                                            month: momentFormat(addMonths(selectedDefaultDate, 2), 'MM'),
                                            year: momentFormat(addMonths(selectedDefaultDate, 2), 'YYYY')
                                        })
                                        setSwipeState(2)
                                    }}
                                >
                                    {momentFormat(addMonths(selectedDefaultDate, 2), 'MMM YY')}
                                </div>
                            </Grid>
                            <Grid item xs={2.75} textAlign={"center"}>
                                <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 3), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                    onClick={() => {
                                        onChange({
                                            month: momentFormat(addMonths(selectedDefaultDate, 3), 'MM'),
                                            year: momentFormat(addMonths(selectedDefaultDate, 3), 'YYYY')
                                        })
                                        setSwipeState(3)
                                    }}
                                >
                                    {momentFormat(addMonths(selectedDefaultDate, 3), 'MMM YY')}
                                </div>
                            </Grid>
                            <Grid item xs={1} textAlign={"center"} justifyContent={"center"}>
                                <div 
                                    // className={form.month === momentFormat(addMonths(new Date(), 3), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                    className='calendar-custom-header'
                                    style={{fontSize: 15, padding: 0}}
                                    onClick={() => {
                                        setOpenCalendar(true)
                                    }}
                                >
                                    <div style={{marginTop: 5}}>
                                        <Icon>eventicon</Icon>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div {...handlers} style={{width: '100vw'}}>
                            <Calendar 
                                className={"react-calendar"}
                                onChange={(e) => {
                                    if (e >= getDate(user)) {
                                        setOpenPackModal({
                                            open: true,
                                            months: 0
                                        })
                                        return
                                    }
                                    let bookingInfo = checkBookingHours(e)
                                    onChange({
                                        selectedDate: toLocalGMT(e),
                                        date: toGMT7(e), 
                                        ...bookingInfo
                                        // hours: hours, 
                                        // totalSessions: 0,
                                        // totalPrice: 0
                                    });
                                }}
                                tileClassName={(({date}) => {
                                    if (moment(date).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")) { return; }
                                    if (date >= getDate(user)) {
                                        return 'disabled-tile'
                                    }
                                    if (moment(date).format('YYYY-MM-DD') == moment(form.selectedDate).format('YYYY-MM-DD')) {
                                        return;
                                    }
                                    if (hourList.indexOf(moment(date).format('YYYY-MM-DD')) >= 0) {
                                        return 'dotted'
                                    }
                                })}
                                minDate={new Date()}
                                // minDate={new Date('2024/05/03')}
                                // maxDate={new Date(moment(addDays(new Date(), user ? (user.package && user.package.advancedDays) ? user.package.advancedDays : 31 : 31)).endOf('month'))} // Count by Days
                                // maxDate={new Date(moment(addMonths(new Date(), user ? (user.activeBenefits && user.activeBenefits.advancedMonths) ? user.activeBenefits.advancedMonths : 0 : 0)).endOf('month'))} // Count by Months
                                defaultValue={form.selectedDate} 
                                value={form.selectedDate}
                                showNavigation={false}
                                // activeStartDate={new Date(`${form.month}-01-${form.year}`)}
                                activeStartDate={new Date(form.year, (form.month - 1), 1)}
                            />
                        </div>
                        <div style={{border: '0.5px solid #3447671F', height: '0.5px', marginTop: 10, width: '100%'}}/>
                        <Grid container item xs={12} md={12} lg={12} mb={16} style={{paddingLeft: 16, paddingRight: 16}}>
                            <CourtComponent courtInfo={courtInfo} form={form} courts={courts} onChange={onChange} checkBookingHours={checkBookingHours}/>
                            <HoursComponent availableHours={availableHours} onChange={onChange} form={form} 
                                navigate={navigate}
                                isNotActiveCalendar={selectedDefaultDate >= getDate(user) || moment(new Date(form.selectedDate)) < moment(new Date('2024/05/03'))}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TCDrawer 
                window={window}
                isOpen={showBooking}
                reRender={reRender}
                titleText={"Booking Summary"}
                title={
                    <FooterAddSchedule
                        calculateSummary={calculateSummary}
                        packUsageInfo={packUsageInfo}
                        booking={booking}
                        loading={loading}
                        checkUserisLoggedIn={checkUserisLoggedIn}
                        onAddBookings={onAddBookings}
                        saveLocalBookingForm={saveLocalBookingForm}
                        setShowLogin={setShowLogin}
                        setShowBooking={setShowBooking}
                        setRerender={setRerender}
                        form={form}
                        isNotActiveCalendar={selectedDefaultDate >= getDate(user)}
                    />
                }
                footer={
                    <div ref={bottomSheetRef}>
                        {
                            (user && booking.length > 0) &&
                            <BookingSummaryBottomSheet
                                calculateSummary={calculateSummary}
                                // coupon
                                coupon={coupon}
                                setCoupon={setCoupon}
                                // valueoack
                                user={user}
                                booking={booking}
                                setOpenModal={setOpenModal}
                                packUsageInfo={packUsageInfo}
                                setPackUsageInfo={setPackUsageInfo}
                                setSelectValuePack={setSelectValuePack}
                                // active deactive
                                couponUsed={coupon != null}
                                valuePackUsed={selectValuePack}
                                loading={loading}
                                setLoading={setLoading}
                                //
                                navigate={navigate}
                            />
                        }
                        <Grid container mt={1} justifyContent={"flex-end"} 
                            style={{paddingLeft: 16, paddingRight: 16}}>
                            <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                                {
                                    packUsageInfo.selectedSession > 0 ?
                                    <TCLabel>Total {packUsageInfo.totalSession - packUsageInfo.selectedSession} {(packUsageInfo.totalSession - packUsageInfo.selectedSession) > 1 ? "Sessions" : "Session"}</TCLabel>
                                    :
                                    <TCLabel>Total {calculateSummary().totalSession} {calculateSummary().totalSession > 1 ? "Sessions" : "Session"}</TCLabel>
                                }
                            </Grid>
                            {
                                (selectValuePack && packUsageInfo) ?
                                <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                                    <TCLabel style={{fontWeight: 'bold'}}>Total <span style={{fontWeight: 'bold'}}>{currencyFormat(packUsageInfo.totalOrder)}</span></TCLabel>
                                </Grid>
                                :
                                <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                                    <TCLabel style={{fontWeight: 'bold'}}>Total <span style={{fontWeight: 'bold'}}>{currencyFormat(calculateSummary().totalPrice)}</span></TCLabel>
                                </Grid>
                            }
                        </Grid>
                        <Grid container mt={1} mb={2} justifyContent={"flex-end"} 
                            style={{paddingLeft: 16, paddingRight: 16}}>
                            <Grid xs={12} md={12} lg={12} item style={{textAlign: 'center'}}>
                                <MKButton color="primary" 
                                    disabled={loading || booking.length <= 0}
                                    fullWidth onClick={() => {
                                    paymentLogic(form, () => {} , setShowLogin, coupon ? coupon.promoCode : '', selectValuePack, setLoading, navigate, () => {
                                        cancelValuePackUse(setPackUsageInfo, setSelectValuePack)
                                        getUserCart(setBooking, setLoading)
                                    })
                                }}>
                                    Pay
                                </MKButton>
                            </Grid>
                        </Grid>
                    </div>
                }
            >
                <BookingDetail bookingData={booking} deleteBookings={onDeleteBookings} onSelectBooking={onSelectBooking} 
                    setCoupon={setCoupon} setCouponCode={setCouponCode} bottomSheetHeight={bottomSheetHeight} 
                    loadUserCart={loadUserCart} packUsageInfo={packUsageInfo}
                />
            </TCDrawer>
            <SlideDialog 
                isOpen={openModal || openPackModal.open || openCalendar} 
                setIsOpen={(e) => {
                    if (openModal) setOpenModal(e)
                    if (openPackModal) setOpenPackModal((prev) => ({
                        ...prev,
                        open: e,
                        months: 0
                    }))
                    if (openCalendar) setOpenCalendar(e)
                }}
                minHeight={(openCalendar) ? "10px" : '80vh'}
                useDialogContent={false}
                maxWidth={"lg"}
            >
                { openModal && <BookingPackSelection bookingData={booking} setOpen={setOpenModal} setSelectValuePack={setSelectValuePack}
                    setPackUsageInfo={(e) => {
                        setPackUsageInfo(prev => ({
                            ...prev,
                            ...e
                        }))
                    }}
                /> }
                { openPackModal.open && 
                    <div style={{minHeight: '85vh'}}>
                        <PopupValuePack closePopUp={ (e) => {
                            setOpenPackModal((prev) => ({
                                ...prev,
                                open: e,
                                months: 0
                            }))
                        }} setShowLogin={(e) => {
                            setOpenPackModal((prev) => ({
                                ...prev,
                                open: false,
                                months: 0
                            }))
                            setShowLogin(e)
                        }} advanceMonth={openPackModal.months} userInfo={user}/>
                    </div> }
                {
                    openCalendar &&
                    <MonthSelection selectedDate={form.selectedDate} 
                    onChangeCalendar={(e) => {
                        setSelectedDefaultDate(e)
                        setSwipeState(0)
                        setForm(prev => ({
                            ...prev,
                            selectedDate: toLocalGMT(e),
                            date: toGMT7(e),
                            month: toGMT7(e, 'MM'),
                            year: toGMT7(e, 'YYYY'),
                            hours: [],
                            totalSessions: 0,
                            totalPrice: 0,
                        }))
                    }}
                    closeCalendar={(e) => {
                        setOpenCalendar(e)
                    }} />
                }
            </SlideDialog>
            {
                loading &&
                <Loading/>
            }
        </>
    )
}

function CourtComponent ({
    courtInfo,
    form,
    courts,
    onChange,
    checkBookingHours
}) {

    const [ imageDetail, setImageDetail ] = useState(false)

    return (
        <Grid container>
            <Grid item mt={2} mb={1} xs={12} md={12} lg={12}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <div style={{marginLeft: 0, display: 'flex', justifyContent: 'center'}}>
                        <TCLabel bold>Court</TCLabel>
                    </div>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {/* <MKButton size="small" type="submit" variant="gradient" color="primary">View Layout</MKButton> */}
                        <div className='mini-btn-custom' onClick={() => {
                            setImageDetail(true)
                        }}>
                            <div>View Layout</div>
                        </div>
                    </div>
                </div>
            </Grid>
            {
                courtType.map( (type, index) => {
                    return (
                        <Grid xs={4} mb={2} item container style={{
                            border: '1px dashed #808080',
                            borderRight: (index == courtType.length - 1) && '1px dashed #808080',
                            padding: 4,
                            borderRadius: 10
                        }}>
                            <Grid xs={12} item textAlign={"center"}>
                                <TCLabel>{type.label}</TCLabel>
                            </Grid>
                            <Grid item container style={{paddingTop: 3}} justifyContent={"center"}>
                            {
                                courts.map( value => {
                                    if (value.courtType === type.value)  {
                                        return (
                                            <Grid item>
                                                <div className={form.court === value._id ? 'selection-selected' : 'selection-available'} style={{textAlign: 'center', padding: 2,
                                                    paddingLeft: 8, paddingRight: 8, 
                                                    minWidth: 25, marginRight: 2, borderRadius: 8}}
                                                    onClick={() => {
                                                        let courtInfo = checkBookingHours(new Date(form.date), value._id)
                                                        onChange({
                                                            court: value._id, 
                                                            ...courtInfo
                                                        })
                                                    }}
                                                >
                                                    {value.name}
                                                </div>
                                            </Grid>
                                        )
                                    }
                                })
                            }
                            </Grid>
                        </Grid>
                    )
                })
            }
            <ImagesDetail
                open={imageDetail}
                setOpen={setImageDetail}
                disableZoom={false}
                src={(courtInfo && courtInfo.layoutPhotos) && loadImages(courtInfo.layoutPhotos[0], true, 500)}
            />
        </Grid>
    )
}

function HoursComponent ({
    form,
    onChange,
    availableHours,
    navigate,
    isNotActiveCalendar
}) {

    const updateSelectedTime = (value) => {

        let index = form.hours.indexOf(value.label);
        let newForm = form.hours;
        let sessions = form.totalSessions;
        let total = form.totalPrice;
        
        if (value.active && index < 0) {
            
            newForm = [ ...form.hours, value.label ];
            sessions += 1;
            total += value.nominee;

        } else if (index >= 0) {
            
            newForm.splice(index, 1);
            sessions -= 1;
            total -= value.nominee;

        }

        onChange({hours: newForm, totalSessions: sessions, totalPrice: total})
    }

    const getEventName = (e) => {
        let name = e.classBooked ? "Class Schedule" : "Event"
        if (e.tournamentBooked === true && e.tournamentObject.length > 0) {
            for (let i = 0; i < e.tournamentObject.length; i ++) {
                if (e.tournamentObject[i].hours.indexOf(e.label) >= 0) {
                    if (e.tournamentObject[i].classLink) {
                        return <div onClick={() => {
                            window.location.href = `/class/detail/${e.tournamentObject[i].classLink}`
                        }}>
                            <TCLabel bold>{e.tournamentObject[i].eventName}</TCLabel>
                        </div>
                    } else {
                        return e.tournamentObject[i].eventName
                    }
                }
            }
        }
        if (e.classBooked === true && e.classObject.length > 0) {
            for (let i = 0; i < e.classObject.length; i ++) {
                if (e.classObject[i].hours.indexOf(e.label) >= 0) {
                    if (e.classObject[i].href) {
                        return <div onClick={() => {
                            window.location.href = `/class/detail/${e.classObject[i].href}`
                            // window.location.href = e.classObject[i].href
                        }}>
                            <TCLabel bold>{e.classObject[i].name}</TCLabel>
                        </div>
                    } else {
                        return e.classObject[i].name
                    }
                }
            }
        }
        return name
    }

    const checkHoursItems = (e) => {
        if (e.listItem && e.listItem.length > 0) { 
            for (let i = 0; i < e.listItem.length; i ++) {
                console.log('here check value', e.listItem[i])
                let hoursInfo = e.listItem[i].hours
                if (hoursInfo.indexOf(e.label) >= 0) { return true; }
            }
        }
        return false
    }

    const checkListItem = (title, e) => {
        let itemList = e.listItem || []
        let listCoach = []
        let listRP = []
        let listBB = []
        let listRent = []
        let showList = true
        if (e.classBooked) {
            showList = false
            if (e.coachedClass) {
                showList = true
            }
        }
        if (!showList) { 
            return {
                info: <Grid container sx={{
                    minHeight: 80,
                    maxHeight: 120,
                    padding: 0,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Grid item xs={12}>
                        <TCLabel bold>{title}</TCLabel>
                    </Grid>
                </Grid>,
                actionBody: null
            }
        }
        for (let i = 0; i < itemList.length; i ++) {
            if (itemList[i].hours.indexOf(e.label) >= 0) {
                if (itemList[i].productInfo.category == "Development Coach" ||
                    itemList[i].productInfo.category == "Lead Coach" ||
                    itemList[i].productInfo.category == "Senior Coach" ||
                    itemList[i].productInfo.category == "Head Coach"
                ) {
                    listCoach.push(itemList[i].productInfo.name)
                    // listCoach.push({
                    //     _id: itemList[i].item,
                    //     name: itemList[i].productInfo.name
                    // })
                }
                if (itemList[i].productInfo.category == "Rally Partner") {
                    listRP.push(itemList[i].productInfo.name)
                    // listRP.push({
                    //     image: itemList[i].productInfo.image,
                    //     name: itemList[i].productInfo.name
                    // })
                }
                if (itemList[i].productInfo.category == "Ball Boy") {
                    listBB.push(itemList[i].productInfo.name)
                    // listBB.push({
                    //     image: itemList[i].productInfo.image,
                    //     name: itemList[i].productInfo.name
                    // })
                }
                if (itemList[i].order && itemList[i].order.orderType == "rent") {
                    listRent.push(itemList[i].productInfo.name)
                    // listRent.push({
                    //     image: itemList[i].productInfo.image,
                    //     name: itemList[i].productInfo.name
                    // })
                }
            }
        }
        console.log('here check coachedClass', e.coachedClass)
        if (e.coachedClass && e.coachedClass.length > 0) {
            // tampilkan seperti di CMS dan counter
            let selectedCoachParticipant = []
            for (let j = 0; j < listCoach.length; j ++) {
                let coachList = []
                for (let k = 0; k < e.classParticipant.length; k ++) {
                let participants = e.classParticipant[k]
                if (participants.service && participants.service.length >= listCoach.length) { continue; }
                if (participants.service && participants.service.indexOf(listCoach[j]._id) >= 0) {
                    coachList.push(participants)
                    selectedCoachParticipant.push(participants._id)
                }
                }
                listCoach[j].coachingList = coachList
            }

            console.log('here list Coach', listCoach)

            return {
                info: <Grid container sx={{
                    minHeight: 80,
                    maxHeight: 120,
                    padding: 0,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Grid item xs={12}>
                        <TCLabel bold>{title}</TCLabel>
                    </Grid>
                    {/* {
                        listCoach && listCoach.length > 0 &&
                        <Grid item xs={12} mt={.5} textAlign={"left"}>
                            <TCLabel style={{fontSize: 10}} bold>Coach: {listCoach.toString()}</TCLabel>
                        </Grid>
                    } */}
                    {
                        (listCoach && listCoach.length > 0) && listCoach.map( v => {
                            return (
                                <Grid container spacing={0.5}>
                                    <Grid item>
                                        <TCLabel style={{fontSize: 10}} bold>{`Coach:`}</TCLabel>
                                    </Grid>
                                    <Grid item>
                                        <TCLabel style={{fontSize: 10}} bold>{`${v.name}`}</TCLabel>
                                    </Grid>
                                    <Grid item contain xs={12} mt={-0.5} textAlign={"left"}>
                                        {
                                            v.coachingList.map( (val, index) => {
                                                if (val.hours) { if (val.hours.indexOf(e.label) < 0) { return; } }
                                                return (
                                                <Grid item>
                                                    <TCLabel style={{fontSize: 10}}>{`${val.attend ? '✔' : ''} ${val.user.name.toString()} (${val.pax}) ${(index !== v.coachingList.length - 1 ? ',' : '')}`}</TCLabel>
                                                </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    {
                        listRP && listRP.length > 0 &&
                        <Grid item xs={12} mt={.5} textAlign={"left"}>
                            <TCLabel style={{fontSize: 10}} bold>Rally Partner: {listRP.toString()}</TCLabel>
                            {/* {
                                listRP.map(v => {
                                    return (
                                        <Grid item xs={12}>
                                            {v.name}
                                        </Grid>
                                    )
                                })
                            } */}
                        </Grid>
                    }
                    {
                        listBB && listBB.length > 0 &&
                        <Grid item xs={12} mt={.5} textAlign={"left"}>
                            <TCLabel style={{fontSize: 10}} bold>Ball Boy: {listBB.toString()}</TCLabel>
                            {/* {
                                listBB.map(v => {
                                    return (
                                        <Grid item xs={12}>
                                            {v.name}
                                        </Grid>
                                    )
                                })
                            } */}
                        </Grid>
                    }
                    {
                        listRent && listRent.length > 0 &&
                        <Grid item xs={12} mt={.5} textAlign={"left"}>
                            <TCLabel style={{fontSize: 10}} bold>Rent: {listRent.toString()}</TCLabel>
                            {/* {
                                listRent.map(v => {
                                    return (
                                        <Grid item xs={12}>
                                            {v.name}
                                        </Grid>
                                    )
                                })
                            } */}
                        </Grid>
                    }
                    {
                        e.classParticipant && e.classParticipant.length > 0 &&
                        <Grid container spacing={0.5}>
                        {/* <Grid item>
                            <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Participant:`}</TCLabel>
                        </Grid> */}
                        <Grid item>
                            <div style={{
                                overflowX: 'auto'
                            }}>
                            {
                                e.classParticipant.map((v, index) => {
                                    if (selectedCoachParticipant.indexOf(v._id) >= 0) { return; }
                                    if (v.hours) { if (v.hours.indexOf(e.label) < 0) { return; } }
                                    return (
                                        <Grid item>
                                            <TCLabel style={{fontSize: 10}}>{`${v.attend ? '✔' : ''} ${v.user.name.toString()} (${v.pax}) ${(index !== e.classParticipant.length - 1 ? ',' : '')}`}</TCLabel>
                                        </Grid>
                                    )
                                })
                            }
                            </div>
                        </Grid>
                        </Grid>
                    }
                </Grid>,
                actionBody: null
            }
        }

        let availableService = listCoach.length > 0 || listRP.length > 0 || listBB.length > 0 || listRent.length > 0
        // let endHours = e.label.split(":")
        // if (endHours.length > 0) { 
        //     endHours = Number(endHours[0]) + 1
        // }
        return {
            info: <Grid container sx={{
                minHeight: 80,
                maxHeight: 120,
                padding: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Grid item xs={12}>
                    <TCLabel bold>{title}</TCLabel>
                    {/* <TCLabel style={{fontSize: 11}} bold>{`${moment(form.date).format('ddd DD/MM/yyyy')} ${e.label} - ${endHours > 9 ? `${endHours}:00` : `0${endHours}:00`}`}</TCLabel> */}
                </Grid>
                {
                    listCoach && listCoach.length > 0 &&
                    <Grid item xs={12} mt={.5} textAlign={"left"}>
                        <TCLabel style={{fontSize: 10}} bold>Coach: {listCoach.toString()}</TCLabel>
                        {/* {
                            listCoach.map(v => {
                                return (
                                    <Grid item xs={12}>
                                        <img src={v.image} width={60} height={'auto'} />
                                        {v.name}
                                    </Grid>
                                )
                            })
                        } */}
                        {/* <TCLabel>{listCoach.toString()}</TCLabel> */}
                    </Grid>
                }
                {
                    listRP && listRP.length > 0 &&
                    <Grid item xs={12} mt={.5} textAlign={"left"}>
                        <TCLabel style={{fontSize: 10}} bold>Rally Partner: {listRP.toString()}</TCLabel>
                        {/* {
                            listRP.map(v => {
                                return (
                                    <Grid item xs={12}>
                                        {v.name}
                                    </Grid>
                                )
                            })
                        } */}
                    </Grid>
                }
                {
                    listBB && listBB.length > 0 &&
                    <Grid item xs={12} mt={.5} textAlign={"left"}>
                        <TCLabel style={{fontSize: 10}} bold>Ball Boy: {listBB.toString()}</TCLabel>
                        {/* {
                            listBB.map(v => {
                                return (
                                    <Grid item xs={12}>
                                        {v.name}
                                    </Grid>
                                )
                            })
                        } */}
                    </Grid>
                }
                {
                    listRent && listRent.length > 0 &&
                    <Grid item xs={12} mt={.5} textAlign={"left"}>
                        <TCLabel style={{fontSize: 10}} bold>Rent: {listRent.toString()}</TCLabel>
                        {/* {
                            listRent.map(v => {
                                return (
                                    <Grid item xs={12}>
                                        {v.name}
                                    </Grid>
                                )
                            })
                        } */}
                    </Grid>
                }
            </Grid>,
            actionBody: (!availableService && e.ownBooked && checkAdvancedDays(form.date)) ?
            // (!availableService && (e.ownBooked || e.classBooked)) ?
            <div onClick={() => {
                // let elem = document.getElementById('alert-dialog-slide-in');
                // elem.parentElement.removeChild(elem);
                // document.getElementById('alert-dialog-slide-description').remove()
                navigate('/add-ons', { reload: true })
            }}>
                <TCLabel style={{
                    fontSize: 11,
                    textDecoration: 'underline'
                }} bold>Book add-on</TCLabel>
            </div> : null
        }
    }

    return (
        <Grid container>
            <Grid mb={1} item>
                <TCLabel bold>Time</TCLabel>
            </Grid>
            <Grid item container spacing={2}>
            {
                availableHours.map( value => {
                    return (
                        <Grid item xs={2}>
                            <div className={
                                    !value.active ? 'selection-disabled' :
                                    isNotActiveCalendar ? 'selection-disabled' :
                                    value.idle ? 'selection-idle' : 
                                    value.classBooked ? 'selection-booked-own' : 
                                    value.tournamentBooked ? 'selection-booked-others' : 
                                    value.booked ? value.ownBooked ? 'selection-booked-own' : 'selection-booked' : 
                                    value.active ? 
                                        form.hours.indexOf(value.label) >= 0 ? 
                                        'selection-selected' : 
                                        'selection-available' 
                                    : 'selection-disabled'
                                } 
                                style={{textAlign: 'center', padding: 5, 
                                    minWidth: 25, minHeight: 50, borderRadius: 8
                                }}
                                onClick={() => {
                                    if (isNotActiveCalendar) { return; }
                                    if (!value.active) { return; }
                                    if (value.idle || value.booked) { 
                                        let information = checkListItem(value.idle ? "Awaiting payment. Please check again" : value.ownBooked ? "My booking"
                                            : (value.classBooked && value.tournamentBooked) ? getEventName(value) : value.classBooked ? getEventName(value) : value.tournamentBooked ?  getEventName(value) : "Court is booked", value)
                                        ConfirmActionBody("", information.info, information.actionBody)
                                        return; 
                                    }
                                    updateSelectedTime(value)
                                }}
                            >
                                {/* <MKTypography variant="small" fontWeight="regular" color="inherit">{value.label}</MKTypography> */}
                                <div>{value.label}</div>
                                {
                                    value.idle ? <Icon>access_time</Icon> : 
                                    value.classBooked ? <Icon>sports_tennis</Icon> : 
                                    value.tournamentBooked ? <Icon>event_available</Icon> : 
                                    value.ownBooked && value.classBooked ? <span>
                                        <Icon>event_available</Icon>
                                        <Icon>sports_tennis</Icon>
                                    </span> :
                                    (value.ownBooked && checkHoursItems(value)) ? <div><Icon>event_available</Icon><img src={addOnSVG} style={{width: '0.9em', height: '1em'}}/></div> : 
                                    // (value.ownBooked && (value.listItem && value.listItem.length > 0)) ? <div><Icon>event_available</Icon><Icon>loupe</Icon></div> : 
                                    value.ownBooked ? <Icon>event_available</Icon> :
                                    value.price &&
                                    // <MKTypography variant="body2" fontWeight="regular" color="inherit">{value.price}</MKTypography>
                                    <div>{value.price}</div>
                                }
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Grid>
    )
}

function FooterAddSchedule ({
    calculateSummary,
    packUsageInfo,
    booking,
    loading,
    checkUserisLoggedIn,
    onAddBookings,
    saveLocalBookingForm,
    form,
    setShowLogin,
    setShowBooking,
    setRerender,
    isNotActiveCalendar
}) {
    return (
        <>
            <Grid container mt={2} justifyContent={"flex-end"} 
                style={{paddingLeft: 16, paddingRight: 16}}>
                <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                    <TCLabel bold>
                    {
                        (packUsageInfo && packUsageInfo.selectedSession > 0) ?
                        <TCLabel>Total {packUsageInfo.totalSession - packUsageInfo.selectedSession} {(packUsageInfo.totalSession - packUsageInfo.selectedSession) > 1 ? "Sessions" : "Session"}</TCLabel>
                        :
                        <TCLabel>Total {calculateSummary().totalSession} {calculateSummary().totalSession > 1 ? "Sessions" : "Session"}</TCLabel>
                    }
                    </TCLabel>
                </Grid>
                <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                    <TCLabel bold>
                        {/* Total {currencyFormat(calculateSummary().totalPrice)} */}
                        {
                            (packUsageInfo && packUsageInfo.selectedSession > 0) ?
                            `Total ${currencyFormat(packUsageInfo.totalOrder)}`
                            :
                            `Total ${currencyFormat(calculateSummary().totalPrice)}`
                        }
                    </TCLabel>
                </Grid>
            </Grid>
            <Grid container mb={0} pb={2} justifyContent={"flex-end"} 
                style={{
                    backgroundColor:'white',
                    paddingLeft: 16, paddingRight: 16 }}
                onTouchStart={(e) => { e.stopPropagation(true) }}
            >
                <Grid mt={1} xs={2} md={2} lg={2} item style={{textAlign: 'center'}}>
                    <MKButton color="white" 
                        disabled={loading || isNotActiveCalendar} 
                        fullWidth
                        onClick={() => {
                            // conditional check
                            let loggedIn = checkUserisLoggedIn();
                            if (loggedIn) { onAddBookings() } else {
                                saveLocalBookingForm(form)
                                setShowLogin(true)
                            }
                        }}
                        sx={{
                            border: 1,
                            color: color.primary
                        }}
                    >
                        <Icon>more_time</Icon>
                        <span style={{marginTop: 2}}>{"Add"}</span>
                    </MKButton>
                </Grid>
                <Grid mt={1} pl={2} xs={10} md={10} lg={10} item style={{textAlign: 'center'}}>
                    <MKButton 
                        color="primary" 
                        disabled={loading || booking.length <= 0}
                        fullWidth 
                        onClick={() => {
                            setShowBooking(true)
                            setRerender(Date.now())
                        }}
                        sx={{
                            backgroundColor: color.primary
                        }}
                    >
                        Checkout
                    </MKButton>
                </Grid>
            </Grid>
        </>
    )
}

function getDate(user) {
    return new Date(moment(addMonths(new Date(), user ? user.advancedSchedule ? user.advancedSchedule : (user.activeBenefits && user.activeBenefits.advancedMonths) ? user.activeBenefits.advancedMonths : 1 : 1)).endOf('month'))
}

function checkAdvancedDays (date, type) {
    if (type === "service") {
        return date <= moment(new Date()).add(14, 'days').toDate()
    } else if (type === "rent") {
        return date <= moment(new Date()).add(7, 'days').toDate()
    } else {
        return date <= forceGMT7(moment(new Date()).add(14, 'days'), 'YYYY-MM-DD')
    }
}

export default BookingCourt